import {Endpoint, Host, AUTH_KEY} from '@env';

console.log(')))')
const Ep = {
	Endpoint,
	Host,
	AUTH_KEY
};

export default Ep;

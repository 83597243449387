import React from 'react';
import {
  Text,
  View,
  Image,
  TextInput,
  TouchableOpacity,
  TouchableHighlight,
  ScrollView,
  KeyboardAvoidingView,
  StyleSheet,
  Modal,
} from 'react-native';

import * as Navigation from '../Routes/Routing';

import styleHelper from '../Styles/StyleHelper';
import Colors from '../Styles/Colors';

import OrderInfo from '../Components/OrderInfo';
import ImageBackground from '../Components/ImageBackground';
import ImageProduct from '../Components/ImageProduct';
import RadioModifierGroup from '../Components/RadioModifierGroup';
import ExtraCharge from '../Components/ExtraCharge';

import Services from '../Utils/Services';
import Device from '../Utils/Device';
import Config from '../Utils/Config';
import API from '../Utils/API';

import CartStore from '../Stores/CartStore';
import OrderStore from '../Stores/OrderStore';
import StoreStore from '../Stores/StoreStore';
import MenuStore from '../Stores/MenuStore';

import CartActions from '../Actions/CartActions';
import OrderActions from '../Actions/OrderActions';
import StoreActions from '../Actions/StoreActions';
import MenuActions from '../Actions/MenuActions';

const styles = StyleSheet.create({
  bottomSeparator: {
    marginBottom: Device.getSize(50),
  },
  suggestionItem: {
    borderColor: '#CCC',
    borderWidth: Device.getSize(1),
    borderStyle: 'solid',
    width: Device.getSize(200),
    height: Device.getSize(160),
    marginRight: Device.getSize(8),
    padding: Device.getSize(7),
  },
  spaceTop: {
    marginTop: Device.getSize(5)
  },
	menuContainer: {
		minWidth: Device.getSize(160),
		maxWidth: Device.getSize(160),
		minHeight: Device.getSize(225),
		flex: 1,
		marginLeft: Device.getSize(20),
		marginRight: Device.getSize(-20)
	},
	breadcum: {
		marginBottom: Device.getSize(5),
		marginTop: Device.getSize(5),
		marginLeft: Device.getSize(25),
		marginRight: Device.getSize(20),
		flex: 1,
		flexDirection: 'row',
		height: Device.getSize(40),
	},
  productImg: {
    width: Device.getSize(140),
    height: Device.getSize(120)
  },
  productBtn: {
    borderWidth: 1,
    borderColor: '#ca6d4f',
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(15),
  },
  cartQtyBox: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(5),
    width: Device.getSize(30),
    textAlign: 'center',
    backgroundColor: Colors.DarkOrange,
  },
  blurBg: {
    width: Device.width,
    height: Device.height,
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
});

const productStyles = StyleSheet.create({
  menuContainer: {
		// minWidth: Device.getSize(290),
		// maxWidth: Device.getSize(290),
    // Width: Device.width * 0.8,
		minHeight: Device.getSize(90),
    // padding: Device.getSize(10),
		// flex: 1,
	},
	container: {
		borderRadius: Device.getSize(5),
		backgroundColor: '#FFF',
    marginHorizontal: Device.getSize(20),
    paddingHorizontal: Device.getSize(0),
    paddingBottom: Device.getSize(20),
		// marginBottom: Device.getSize(10),
		// shadowColor: '#000',
		// shadowOffset: {
		//   width: 3,
		//   height: 4
		// },
		// shadowRadius: 5,
		// shadowOpacity: 0.5
  },
  popupContainer: {
    width: Device.width * 0.9,
    height: Device.height ? Device.height <= 926 ? Device.height * 0.9 : Device.getSize(500) : Device.getSize(500),
    backgroundColor: '#FFF',
    paddingVertical: Device.getSize(10),
    // paddingHorizontal: Device.getSize(10),
    marginTop: Device.getSize(20),
    marginBottom: Device.getSize(20),
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: Device.getSize(10)
  },
	customBtn: {
	  borderRadius: Device.getSize(10),
	  width: Device.width * 0.8,
	  maxHeight: Device.getSize(30),
	  marginTop: Device.getSize(10)
  },
  qtyInput: {
    width: Device.getSize(100)
  }
});

export default class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.onCartChange = this.onCartChange.bind(this);
        this.navigateMenu = this.navigateMenu.bind(this);
        this.renderTopNav = this.renderTopNav.bind(this);
        this.renderConfirmOrder = this.renderConfirmOrder.bind(this);
        this.onConfirmOrder = this.onConfirmOrder.bind(this);

        this.state = {
            refreshingData: false,
            grandTotal: CartStore.getGrandTotal(),
            //   hasCard: PaymentStore.hasCard(),
            hasCard: false,
            numberValid: false,
            expiryValid: false,
            cvcValid: false,
            cardNumber: null,
            expiryMonth: null,
            expiryYear: null,
            cvc: null,
            spawnSlash: true,
            spawnDash1: true,
            spawnDash2: true,
            spawnDash3: true,
            spawnDash4: true,
            showPaymentCard: false,
        };
    }

    componentDidMount() {
        // CartStore.addExpireCartListener(this.onCartExpire);

        // if (_isOrderTimeExpired() === true) {
        //   return false;
        // }

        CartActions.postOrder(OrderStore.getData());
        // CartActions.getAllPromotions(OrderStore.getData());

        // PromotionActions.getPromotionsFromServer();
        // CartStore.addSuccessfulPaymentListener(this.onSuccessfullyPay);
        CartStore.addChangeProductCart(this.onCartChange);
        // CustomerActions.getLatestPoints();

        if(!CartStore.getCustomCartId()) {
            let cartId = new Date().getTime();
            CartActions.setCustomCartId(cartId);
        }
    }

    componentWillUnmount() {
        // CartStore.removeExpireCartListener(this.onCartExpire);
        // CartStore.removeSuccessfulPaymentListener(this.onSuccessfullyPay);
        CartStore.removeChangeProductCart(this.onCartChange);
    }

    onCartChange() {
        this.setState({
        grandTotal: CartStore.getGrandTotal(),
        });
    }

    navigateMenu() {
      Navigation.navigate('Home')
    }

    getData(){
        let data = [];

        //Add products
        let products = CartStore.getDisplayProducts();
        products.forEach((product)=>{
            let orderProduct = [0, 0, { // 0,0 => create new record in odoo
              name: product.name,
              qty: product.quantity,
              price_unit: product.price,
              price_subtotal: 0,
              subtotal: 0,
              price_subtotal_incl: 0,
              modifier_line: []
            }];
            if(product.variant){
                orderProduct[2].product_id = product.variant.id;
            }
            product.modifiers.forEach((modifier)=>{
                orderProduct[2].modifier_line.push({
                  id: modifier.id,
                  qty: modifier.quantity,
                  product_id: modifier.product_id.id,
                  name: modifier.product_id.name,
                  price: modifier.price,
                  price_subtotal: 0,
                  subtotal: 0,
                  price_subtotal_incl: 0
                });
            })
            data.push(orderProduct);
        });

        return data;
    }

    onConfirmOrder() {
      Services.showSpinner();

      let store = OrderStore.getData();
      let cartId = CartStore.getCustomCartId();
      if(!CartStore.getCustomCartId()) {
          cartId = new Date().getTime();
          CartActions.setCustomCartId(cartId);
      }
      this.amount = CartStore.getGrandTotal();
      this.amount = parseFloat(this.amount.toFixed(2));
      // let param = {
      //   args: [StoreStore.getQRToken(), {
      //     state: 'draft',
      //     pos_reference: 'Order ' + cartId,
      //     amount_tax: 0,
      //     amount_total: this.amount,
      //     amount_paid: 0,
      //     amount_return: 0,
      //     table_id: parseInt(store.tableId),
      //     table_ids: [[6, 0, [parseInt(store.tableId)]]], // 6,0 => replace all records in list
      //     lines: this.getData()
      //   }, StoreStore.getSummaryIp()],
      //   kwargs: {},
      //   context: {}
      // };
      let param = {
        jsonrpc: '2.0',
        params: {
          token: StoreStore.getQRToken(),
          summary_printer_ip: StoreStore.getSummaryIp(),
          order: {
            state: 'draft',
            pricelist_id: parseInt(StoreStore.getPriceListId()),
            session_id: parseInt(StoreStore.getSessionId()),
            user_id: parseInt(StoreStore.getUserId()),
            pos_reference: 'Order ' + cartId,
            amount_tax: 0,
            amount_total: this.amount,
            amount_paid: 0,
            amount_return: 0,
            table_id: parseInt(store.tableId),
            table_ids: [[6, 0, [parseInt(store.tableId)]]], // 6,0 => replace all records in list
            lines: this.getData()
          }
        }
      };

      API.createOrder(param, {
        success: (res)=>{
          console.log(res)
          if(res.result.id) {
            Navigation.navigate('Confirmation')
          }
        },
        error: (err)=>{
          console.log(err)
          Services.showAlertError(err.error_descrip);
        },
        complete: ()=>{
          Services.hideSpinner();
        }
      });
    }

    renderConfirmOrder() {
        return (
            <View
                style={[
                    styleHelper.flexRow,
                    // styleHelper.borderTopWhiteTransparent,
                    styleHelper.flexCenter,
                    {backgroundColor: '#a1412b', borderRadius: Device.getSize(5), marginTop: Device.getSize(10)}
                ]}>

                <TouchableOpacity
                    style={[
                        styleHelper.containerNormal,
                        styleHelper.flexCenter,
                        styleHelper.flex1,
                        styleHelper.flexRow,
                    ]}
                    onPress={()=>{ this.onConfirmOrder() }}>
                    <Text
                        style={[
                            styleHelper.fontWhite,
                            styleHelper.font18,
                            styleHelper.fontBold,
                        ]}>
                        Confirm Order
                    </Text>
                </TouchableOpacity>

            </View>
        )
    }

    renderTopNav() {
      return(
        <View style={[styleHelper.flexRow, {backgroundColor: '#ca6d4f', height: Device.getSize(50)}]}>
            <View style={[styleHelper.flex1, {marginVertical: Device.getSize(15), marginLeft: Device.getSize(10)}]}>
                <TouchableOpacity onPress={()=>{ this.navigateMenu(); }}>
                    <Text style={[styleHelper.fontWhite, styleHelper.font15, styleHelper.fontHelveticaNeueBold]}>
                        &lt;
                    </Text>
                </TouchableOpacity>
            </View>
  
            <View style={[styleHelper.flex1, styleHelper.flexCenter]}>
                <Text style={[styleHelper.fontWhite, styleHelper.font16, styleHelper.fontHelveticaNeueBold]}>
                    CART
                </Text>
            </View>
  
            <View style={[styleHelper.flex1]} />
        </View>
      )
    }
  
    render() {
        return (
            <ImageBackground>
                {/* {this.renderPaymentCard()} */}
                {this.renderTopNav()}
                <View style={[styleHelper.flex1, styleHelper.flexColumn, {padding: Device.getSize(10)}]}>
                    <OrderInfo />

                    <View style={[{flex: 1}]}>
                        <KeyboardAvoidingView behavior={'position'}>
                            <CartDetails />
                        </KeyboardAvoidingView>
                    </View>

                    <View style={[styleHelper.bgOrange]}>
                      <Subtotal />
                      <ExtraCharges />
                      <GrandTotal />
                    </View>

                    {this.renderConfirmOrder()}
                </View>
            </ImageBackground>
        );
    }
}

class CartDetails extends React.Component {
    constructor(props) {
        super(props);    
        this.navigateToMenu = this.navigateToMenu.bind(this);
    }

    navigateToMenu() {
        Navigation.navigate('Home');
    }

    render() {
        return (
            <View>
                {/* <View>
                  <TouchableOpacity
                    onPress={() => this.navigateToMenu()}
                    activeOpacity={Config.activeOpacity}
                    style={[styleHelper.flex1, {marginTop: Device.getSize(10)}]}>

                    <Text
                        style={[
                        styleHelper.fontBold,
                        styleHelper.fontOrange,
                        styleHelper.font14,
                        {textAlign: 'right', fontWeight: 'bold'}
                        ]}>
                        &lt; Back to menu
                    </Text>

                  </TouchableOpacity>
                </View>
 */}
                <View style={[{height: Device.height * 0.55, borderRightWidth: 2, borderRightColor: '#CCC', borderStyle: 'solid', padding: Device.getSize(5)}]}>
                    <Products navigation={this.props.navigation} />
                </View>
            </View>
        );
    }
}

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.onVariantChange = this.onVariantChange.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.applyTssCustomModifier = this.applyTssCustomModifier.bind(this);
    this.handleModifierScroll = this.handleModifierScroll.bind(this);
    this.renderModifiers = this.renderModifiers.bind(this);
    this.modifier = this.modifier.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
    this.renderAdd = this.renderAdd.bind(this);
    this.updateModifierValid = this.updateModifierValid.bind(this);
    this.toggleCustomModifiers = this.toggleCustomModifiers.bind(this);
    this.updateModifier = this.updateModifier.bind(this);
    this.reselectModifier = this.reselectModifier.bind(this);
    this.deselectModifier = this.deselectModifier.bind(this);
    this.resetModifierGroup = this.resetModifierGroup.bind(this);
    this.renderProductQty = this.renderProductQty.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.hideProduct = this.hideProduct.bind(this);
    this.state = {
      products: CartStore.getServerProducts(),
      modifierNodes: new Map(),
      productQty: 1,
      isOne: false,
      showProduct: false,
      productSubtotal: OrderStore.getCurrentSubtotal(),
      modifierGroups: [],
      modifierGroupsValid: [],
      modifierY: 0,
      imgHeight: 0,
      imgWidth: 0,
    };
  }

  removeProduct(cartID) {
    //Reset rewards point when deleting product
    // CartActions.setRewardPoints(0);
    CartActions.removeProduct(cartID);

		// // recalculate discount
		// let tssDiscount = CartStore.getTssDiscounts();
		// if(tssDiscount.length > 0){
		// 	if(tssDiscount[0].id > 0 && tssDiscount[0].value > 0) {
		// 		// Wait till products updated
		// 		setTimeout(() => {
		// 			CartActions.selectTssVoucher(tssDiscount[0].id, tssDiscount[0].voucherCode);
		// 		}, 500);
		// 	}
		// }
  }

  // apply TSS custom modifier logic
  applyTssCustomModifier() {
    let modifierMaps = MenuStore.getModifierMap();
    let modifierGroups = OrderStore.getModifierGroups();
    let newModifierGroups = [];
    modifierGroups.forEach((modifierGroup)=>{
      modifierGroup.hide = false;
      modifierGroup.modifier1 = 0;

      // DB was designed for multi modifiers, but temporarily treat as single modifier
      // apply_to_show was currently unused bcos leak of time to do (urgently needed)
      let qty = 0;
      modifierMaps.forEach(modifierMap => {
        if(modifierGroup.id == modifierMap.modifier2 && modifierMap.apply_to_show) {
          qty = 0;
          // check if parent selected
          modifierGroups.forEach((mg)=>{
            if(mg.id == modifierMap.modifier1) {
              mg.product_modifier_ids.forEach(m => {
                qty += m.quantity;
              });
            }
          });

          if(qty < 1) {
            modifierGroup.hide = true; // hide if parent not selected
          } else {
            modifierGroup.hide = false; // show if parent selected
          }
          modifierGroup.modifier1 = modifierMap.modifier1;
        }
      });
      newModifierGroups.push(modifierGroup);
    });

    return newModifierGroups;
  }
  
  hideProduct() {
    this.setState({
      showProduct: false,
      modifierGroupsValid: [true, true, true, true, true, true],
      productQty: 1,
      isOne: false
    });
  }

  editProduct(cartID) {
    OrderActions.selectProduct(CartStore.findProduct(cartID));

    let modifierValids = [];
    OrderStore.getModifierGroups().forEach((modifier, i)=>{
      modifierValids.push(true);
    });

    let modifierGroups = this.applyTssCustomModifier();
    
    this.setState({
      showProduct: true,
      productSubtotal: OrderStore.getCurrentSubtotal(),
      modifierGroups: modifierGroups,
      modifierGroupsValid: modifierValids,
      modifierY: 0,
    });
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
		OrderStore.addChangeVariantListener(this.onVariantChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
		OrderStore.removeChangeVariantListener(this.onVariantChange);
  }

  onCartChange() {
    this.setState({
      products: CartStore.getServerProducts(),
    });

    // redirect to menu if empty cart
    if (this.state.products.length < 1) {
      Navigation.navigate('Home');
    }
  }

  onVariantChange(){
    let modifierGroups = this.applyTssCustomModifier();
		this.setState({
      productSubtotal: OrderStore.getCurrentSubtotal(),
			modifierGroups: modifierGroups
		});
	}

  handleModifierScroll(event) {
    this.setState({
      modifierY: event.nativeEvent.contentOffset.y
    });
  }

  // PRODUCT
  addProduct() {
    const product = OrderStore.getSelectedProduct();

    if(OrderStore.isSelectedModifierValid() === true) {
      CartActions.addProductToCart(product, this.state.productQty);
      CartActions.postOrder(OrderStore.getData());

      this.setState({
        showProduct: false,
        // reset qty
        productQty: 1,
        isOne: false
      });

    } else {
      // Services.showAlertError('Please select some modifiers in each group');
      this.setState({
        modifierGroupsValid: OrderStore.checkModifierGroupsValid(),
      });

      try {
        OrderStore.checkModifierGroupsValid().forEach((modifierValid, i)=>{
          if(!modifierValid) {
            const modifierNode = this.state.modifierNodes.get(i);
            const position = findNodeHandle(modifierNode);
            RCTUIManager.measure(position, (fx, fy, w, h, px, py)=>{
              this.setState({
                modifierY: this.state.modifierY + py - 300
              });
              this.modifierScroll.scrollTo({x: 0, y: this.state.modifierY + py - 300, animated: true});
            });
            // throw BreakException;
          }
        });
      } catch(e) {
        // do nothing
      }
    }
  }
    
  renderProductQty(qty) {
    return(
        <View style={[styleHelper.flexRowSpaceBetween, productStyles.qtyInput]}>
          <IconMinus onPress={()=> {
            if(qty > 1 && this.state.productQty <= 1 && !this.state.isOne) {
              this.setState({
                productQty: qty-1
              })

              // trick's key
              if(this.state.productQty == 1) {
                this.setState({
                  isOne: true
                })
              }
            } else {
              if(this.state.productQty > 1) {
                this.setState({
                  productQty: this.state.productQty-1
                })
              }
            }
          }} />
          <View
            style={[
              styleHelper.inputBorderRadius,
              styleHelper.bgWhite,
              styleHelper.containerNumber2,
            ]}>
            <Text
              style={[
                styleHelper.fontRegular,
                styleHelper.fontBlack,
                styleHelper.font16,
                styleHelper.textCenter,
              ]}>
              {this.state.productQty > 1 ? this.state.productQty : this.state.isOne ? 1 : qty > 0 ? qty : 1}
            </Text>
          </View>
          <IconPlus onPress={()=> {
            if(qty > 0 && this.state.productQty <= 1 && !this.state.isOne) {
              this.setState({
                productQty: qty+1
              })
            } else {
              this.setState({
                productQty: this.state.productQty+1
              })
            }
          }} />
        </View>
    )
  }

  // MODIFIERS
  updateModifierValid(index) {
    let modifierGroupsValid = this.state.modifierGroupsValid;
    modifierGroupsValid[index] = OrderStore.checkModifierGroupsValid()[index];
    this.setState({
      modifierGroupsValid: modifierGroupsValid,
    });
  }

  toggleCustomModifiers(modifierGroup, hide) {
    let newMG = [];
    let parentModifier = 0;
    this.state.modifierGroups.forEach((mg)=>{
      // recursive call for children
      if(parentModifier != 0) {
        this.state.modifierGroups.forEach((mg2)=>{
          if(parentModifier == mg2.modifier1) {
            parentModifier = 0;
            this.toggleCustomModifiers({id: mg2.id}, hide);
          }
        });
      }

      if(mg.id == modifierGroup.id) {
        if(hide) {
          parentModifier = mg.id;
          this.resetModifierGroup(mg);
        } else {
          mg.hide = hide;
        }
      }

      newMG.push(mg);
    });

    this.setState({
      modifierGroups: newMG
    });
  }

  updateModifier(modifier, modifierGroup, index) {
    OrderActions.selectModifier(modifier);
    this.updateModifierValid(index);

    // show certain modifierGroup
    this.toggleCustomModifiers(modifierGroup, false);
  }

  reselectModifier(modifier, modifierGroup, index) {
    OrderActions.selectModifierV2(modifier, modifierGroup);
    this.updateModifierValid(index);
  }

  deselectModifier(modifier, modifierGroup, index) {
    OrderActions.deselectModifier(modifier);
    this.updateModifierValid(index);

    // hide certain modifierGroup
    this.toggleCustomModifiers(modifierGroup, true);
  }

  resetModifierGroup(modifierGroup) {
    OrderActions.resetModifierGroup(modifierGroup);
  }

  renderAdd(modifier, modifierGroup, index) {
    /**
     * Selected
     * Max selection is 1
     */
		if(modifier.max_qty == 1 && modifier.quantity > 0){
			if(modifier.preSelected){
				return(
          <TouchableRadio
            disabled={true}
            selected={true} />
			  	)
			} else {
				return (
        <TouchableRadio
          selected={true}
          modifier={modifier}
          modifierGroup={modifierGroup}
          index={index}
          onPress={this.deselectModifier} />
        )
      }
		} else {
      /**
       * Not Selected, OR Selected but max qty not reached
       * Max qty of modifier group not reached
       */
			if(modifierGroup.numberSelectedModifier < modifierGroup.max_modifier || modifier.quantity === modifier.max_qty){
				if(modifier.max_qty > 1){
					//Render quantity box
					return(
						<BoxQuantity 
							onPressMinus={()=>{
								OrderActions.deselectModifier(modifier);
                this.updateModifierValid(index);
							}}
							onPressPlus={()=>{
								OrderActions.selectModifier(modifier);
                this.updateModifierValid(index);
							}}
							modifier={modifier}/>
					)
				} else {
					return (
            <TouchableRadio
              selected={false}
              modifier={modifier}
              modifierGroup={modifierGroup}
              index={index}
              onPress={this.updateModifier} />
					)
				}
      /**
       * Selected but max qty not reached
       * Max qty or modifier group reached
       * else chunk below almost imposible to trigger
       */
			} else { // not selected, 
				if(modifier.max_qty > 1){
					//Render quantity box
					return(
						<BoxQuantity 
							onPressMinus={()=>{
								OrderActions.deselectModifier(modifier)
                this.updateModifierValid(index);
							}}
							disabled={true}
							modifier={modifier}/>
					)
				} else { // almost imposible to trigger
					return (
            <TouchableRadio
              selected={false}
              modifier={modifier}
              modifierGroup={modifierGroup}
              index={index}
              onPress={this.reselectModifier} />
					)
				}
			}
		}
	}

	renderPrice(price) {
		if(price > 0){
			return(
				<Text style={[styleHelper.fontGrey, styleHelper.fontHelveticaNeueRegular, styleHelper.font10, styleHelper.containerHorizontalSmall]}>
					(+{Services.formatPrice(price)})
				</Text>
			)
		}
  }

  modifier(modifierGroup, index) {
    let storeId = StoreStore.getSelectedStore().id;
    let oos = false;
    return(
      <View>
        {
          modifierGroup.product_modifier_ids.map((modifier, i)=>{
            oos = false;
            modifier.oos_store.forEach(shop_id => {
              if(shop_id.id == storeId) {
                oos = true;
              }
            });

            if(!oos) {
              return (
                <View key={i} style={[styleHelper.flexRowSpaceBetweenAlignTop, styleHelper.containerVerticalSmall2]}>
                  <View style={styleHelper.flex1}>
                    <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font12, styleHelper.DarkGreenFont, modifier.quantity > 0 && styleHelper.DarkFont]}>
                      {modifier.product_id.name}
                    </Text>
                  </View>
      
                  <View style={styleHelper.flexRowSpaceBetween}>
                    {this.renderPrice(modifier.price)}
                    {this.renderAdd(modifier, modifierGroup, index)}
                  </View>
                </View>
              )
            } else {
              return <View key={i} />
            }
          })
        }
      </View>
    )
  }

  renderModifiers() {
		if(this.state.modifierGroups.length ===  0){
			return null;
		}

		return(
			<View>
				{
					this.state.modifierGroups.map((modifierGroup, i)=>(
            !modifierGroup.hide ?
            (
              <View style={styleHelper.containerVerticalNormal} key={i}>
                <View style={[styleHelper.flexRow]}>
                  {
                    !this.state.modifierGroupsValid[i] &&
                    <View style={[styleHelper.flexCenter, {borderRadius: Device.getSize(20), backgroundColor: '#F00', width: Device.getSize(15), height: Device.getSize(15), marginRight: Device.getSize(5), padding: Device.getSize(5)}]}>
                      <Text style={[styleHelper.fontWhite, styleHelper.font12, styleHelper.fontHelveticaNeueRegular]}>
                        !
                      </Text>
                    </View>
                  }

                  <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font12, styleHelper.containerVerticalTiny, styleHelper.DarkGreenFont, !this.state.modifierGroupsValid[i] && styleHelper.fontRed]}
                    ref={(ref) => {
                      this.state.modifierNodes.set(i, ref);
                    }}>
                    {modifierGroup.name}
                  </Text>
                </View>

                <View style={[styleHelper.borderBottomWhiteTransparent, styleHelper.flexRow]}>
                  <Text style={[styleHelper.fontHelveticaNeue, styleHelper.font11, styleHelper.fontBlackSecond, styleHelper.containerVerticalTiny]}>
                    {modifierGroup.description}
                  </Text>
                </View>

                {this.modifier(modifierGroup, i)}
              </View>
            ) : <View key={i} />
					))
				}
			</View>		
		)
  }

  // Menu Product Popup
  renderProduct() {
    let product = OrderStore.getSelectedProduct();
    // let images = [];
    let cart = require('../img/ic_cart_white.png');
    if(!product) {
      return <View />;
    }
    // if(product.id){
    //   images[product.id] = this.cacheImage(product.id, product.image_id);
    // }

    Image.getSize(Services.getImageURL(product.id, 'product'), (imageWidth, imageHeight) => {
      let scaledImage = Services.getImageScaledSize(imageWidth, imageHeight, Device.width * 0.9);
      this.setState({
        imgWidth: scaledImage.width,
        imgHeight: scaledImage.height
      })
    });

    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.showProduct}>
        
        <View style={[styleHelper.flex1, styles.blurBg, {alignItems: 'center'}]}>
          <View style={[productStyles.popupContainer]}>

            <View style={[styleHelper.borderBottomBlackTransparent, styleHelper.flexCenter, {paddingVertical: Device.getSize(10)}]}>
              <Text style={[styleHelper.font16, styleHelper.fontHelveticaNeueBold, styleHelper.OrangeDarkFont]}>
                Product
              </Text>
              
              <TouchableOpacity
                onPress={()=>this.hideProduct()}
                style={[{position: 'absolute', top: Device.getSize(5), right: Device.getSize(5)}]}>
                  <Image
                    source={require('../Img/ic_close.png')}
                    resizeMode={'contain'}
                    style={[{width: Device.getSize(20), height: Device.getSize(20)}]} />
                {/* <Text style={[styleHelper.font25, styleHelper.fontHelveticaNeueBold, styleHelper.OrangeDarkFont]}>X</Text> */}
              </TouchableOpacity>
            </View>

            <ScrollView style={[{borderRightColor: '#CCC', borderRightWidth: 2, paddingHorizontal: Device.getSize(5)}]}
              ref={(ref)=>{
                this.modifierScroll = ref
              }}
              scrollEventThrottle={0}
              onScroll={this.handleModifierScroll}>
                {/* <View style={[styleHelper.flexRow]}> */}
                  <Image
                      source={{
                          uri: Services.getImageURL(product.id, 'product')
                      }}
                      resizeMode={'contain'}
                      style={[{width: this.state.imgWidth, height: this.state.imgHeight}]} />
                {/* </View> */}

                <View style={[{paddingHorizontal: Device.getSize(10)}]}>
                  <View style={[styleHelper.flexRowSpaceBetween, {marginBottom: Device.getSize(10)}]}>
                    <Text
                      style={[
                        styleHelper.fontHelveticaNeueBold,
                        styleHelper.fontBlack,
                        styleHelper.font14,
                        styleHelper.containerVerticalNormal,
                        {flex: 2}
                      ]}>
                      {product.name}
                    </Text>

                    <View style={[{flex: 1}]}>
                      <Text
                        style={[
                          styleHelper.fontHelveticaNeueBold,
                          styleHelper.fontBlack,
                          styleHelper.font12,
                          styleHelper.containerVertical2,
                          {textAlign: 'right'}
                        ]}>
                        {Services.formatPrice(this.state.productSubtotal)}
                      </Text>

                      <Text
                        style={[
                          styleHelper.fontHelveticaNeueRegular,
                          styleHelper.fontXDark,
                          styleHelper.font12,
                          {textAlign: 'right'}
                        ]}>
                        Base price
                      </Text>
                    </View>
                  </View>

                  <ProductDescription description={product.description_sale}/>

                  <View style={[{paddingTop: Device.getSize(15)}]}>
                    {product.product_variant_ids.length > 1 &&
                      <RadioModifierGroup data={product.product_variant_ids} />
                    }
                    {this.renderModifiers()}
                  </View>
                </View>
            </ScrollView>

            <View style={[styleHelper.flexCenter, {paddingHorizontal: Device.getSize(5)}]}>
                {this.renderProductQty(product.quantity)}

                <TouchableOpacity
                  onPress={()=>{this.addProduct()}}
                  activeOpacity={Config.activeOpacity} 
                  style={[styleHelper.OrangeDarkBg, productStyles.customBtn, styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter]}>

                  <Image 
                      source={cart}
                      style={styleHelper.verysmallIconSize}
                      resizeMode={'contain'} />

                  <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.fontWhiteGold, styleHelper.font14, styleHelper.textCenter]}>
                    {!product.cartID ? 'ADD TO CART' : 'UPDATE ITEM'} - {Services.formatPrice(this.state.productSubtotal * this.state.productQty)}
                  </Text>

                </TouchableOpacity>
            </View>

          </View>
        </View>

      </Modal>
    )
  }
  
  renderProductPrice(product) {
    //   let price = 0;
    //   product.modifiers.forEach((modifier)=>{
    //     price += modifier.price * modifier.quantity * product.quantity;
    //   });
    //   return (
    //     <Text>{Services.formatPrice(product.total-price)}</Text>
    //   );
    return (
        <Text>{Services.formatPrice(product.productPrice * product.quantity)}</Text>
    );
  }

  render() {
    return (
      <View>
        {this.renderProduct()}

        {/* nestedScrollEnabled={true} */}
        <ScrollView keyboardShouldPersistTaps="always" style={[{height: Device.height * 0.55}]}>
        {this.state.products.map((product, key) => (
          <View
            key={key}
            style={[
              styleHelper.containerVeryTiny,
            ]}>
            <View
              style={[
                styleHelper.flexRowSpaceBetweenAlignTop,
                styleHelper.containerVerticalSmall,
              ]}>
              <Text
                style={[
                  styleHelper.flex1,
                  styleHelper.fontRegular,
                  styleHelper.font16,
                  styleHelper.fontBlack,
                ]}>
                {product.quantity}x {product.productName}
              </Text>
              <Text
                style={[
                  styleHelper.fontRegular,
                  styleHelper.font16,
                  styleHelper.fontBlack,
                ]}>
                {this.renderProductPrice(product)}
              </Text>
            </View>

            <ProductVariant product={product} />

            {product.modifiers.map((modifier, _key) => (
              <View
                key={_key}
                style={[
                  styleHelper.flexRowSpaceBetweenAlignTop,
                  styleHelper.containerVeryXTiny,
                ]}>
                <Text
                  style={[
                    styleHelper.flex1,
                    styleHelper.fontRegular,
                    styleHelper.font12,
                    styleHelper.fontBlack,
                  ]}>
                  {product.quantity * modifier.quantity}x {modifier.modifierName}
                </Text>
                <Text
                  style={[
                    styleHelper.fontRegular,
                    styleHelper.font12,
                    styleHelper.fontBlack,
                  ]}>
                  {Services.formatPrice(modifier.price * modifier.quantity * product.quantity)}
                </Text>
              </View>
            ))}

            <View
              style={[
                styleHelper.flexJustifyEnd,
                styleHelper.flexRow,
                styleHelper.alignSelfStretch,
                styleHelper.containerVerticalSmall,
              ]}>

              <TouchableOpacity
                activeOpacity={Config.activeOpacity}
                onPress={() => this.editProduct(product.cartID)}
                style={[styles.productBtn]}>
                <Text style={[styleHelper.fontOrange, styleHelper.fontRegular, styleHelper.font14]}>
                  Edit
                </Text>
              </TouchableOpacity>

              <View style={styleHelper.containerHorizontalSmall} />

              <TouchableOpacity
                activeOpacity={Config.activeOpacity}
                onPress={() => this.removeProduct(product.cartID)}
                style={[styles.productBtn]}>
                <Text style={[styleHelper.fontOrange, styleHelper.fontRegular, styleHelper.font14]}>
                  Remove
                </Text>
              </TouchableOpacity>

            </View>
          </View>
        ))}
        </ScrollView>
      </View>
    );
  }
}

class Subtotal extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.state = {
      subtotal: null,
      extraCharges: null,
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
  }

  onCartChange() {
    this.setState({
      subtotal: CartStore.getSubtotal(),
      extraCharges: CartStore.getExtraCharge(),
    });
  }

  render() {
    if (this.state.subtotal === null) {
      return null;
    }

    // let itemCount = CartStore.getQuantity();
    return (
      <View
        style={[
          styleHelper.customContainerNormal,
          styleHelper.flexRowSpaceBetween,
        ]}>
        <Text
          style={[
            styleHelper.font14,
            styleHelper.fontWhite,
            styleHelper.fontBold,
          ]}>
          Subtotal
        </Text>
        <Text
          style={[
            styleHelper.fontWhite,
            styleHelper.font14,
            styleHelper.fontBold,
          ]}>
          {Services.formatPrice(this.state.subtotal)}
        </Text>
      </View>
    );
  }
}

class ExtraCharges extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.state = {
      extraCharges: [],
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
  }

  onCartChange() {
    this.setState({
      extraCharges: CartStore.getExtraCharge(),
    });
  }

  render() {
    if(this.state.extraCharges) {
      if (this.state.extraCharges.length === 0) {
        return null;
      }
    }
    return (
      <View>
        {this.state.extraCharges.map((extraCharge, k) => (
          extraCharge.amount > 0 &&
          <View
            key={k}
            style={[
              styleHelper.customContainerNormal,
              styleHelper.flexRowSpaceBetween,
            ]}>
            <View style={styleHelper.flexRowSpaceBetween}>
              {extraCharge.percentage && (
                <Text
                  style={[
                    styleHelper.font12,
                    styleHelper.fontWhite,
                    styleHelper.fontRegular,
                    styleHelper.containerHorizontalSmall,
                  ]}>
                  {extraCharge.percentage}%
                </Text>
              )}
              <Text
                style={[
                  styleHelper.font14,
                  styleHelper.fontWhite,
                  styleHelper.fontRegular,
                ]}>
                {extraCharge.string}
              </Text>
            </View>

            <Text
              style={[
                styleHelper.fontWhite,
                styleHelper.font14,
                styleHelper.fontRegular,
              ]}>
              {Services.formatPrice(extraCharge.amount)}
            </Text>
          </View>
        ))}
      </View>
    )
  }
}

class GrandTotal extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.state = {
      grandTotal: null,
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
  }

  onCartChange() {
    this.setState({
      grandTotal: CartStore.getGrandTotal(),
    });
  }

  renderNumber() {
    return (
      <Text
        style={[
          styleHelper.fontWhite,
          styleHelper.font14,
          styleHelper.fontBold,
        ]}>
        {Services.formatPrice(this.state.grandTotal)}
      </Text>
    );
  }

  render() {
    return (
      <View
        style={[
          styleHelper.customContainerNormal,
          styleHelper.flexRowSpaceBetween,
        ]}>
        <Text
          style={[
            styleHelper.font14,
            styleHelper.fontWhite,
            styleHelper.fontRegular,
          ]}>
          Grand Total
        </Text>
        {this.renderNumber()}
      </View>
    );
  }
}

const ProductVariant = props => {
  if (props.product.product_variant_ids) {
    return (
      <View style={[styleHelper.flexRowSpaceBetweenAlignTop, styleHelper.containerVeryXTiny]}>
        <Text
          style={[
            styleHelper.flex1,
            styleHelper.fontRegular,
            styleHelper.font12,
            styleHelper.fontDark,
          ]}>
          {props.product.attribute_value_ids[0].name}
        </Text>
        {/* <Text
          style={[
            styleHelper.fontRegular,
            styleHelper.font12,
            styleHelper.fontDark,
          ]}>
          {Services.formatPrice(props.product.variantPrice)}
        </Text> */}
      </View>
    );
  }
  return null;
};

// PRODUCTS
const ProductDescription = (props)=>{
  return(
    <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font12]}>
      {props.description || ''}
    </Text>
  )
}

// Modifiers
const IconPlus = (props)=>{
	if(props.disabled === true){
		return(
			<View style={{opacity: 0.5}}>
				<ImageIconPlus/>
			</View>
		)
	}

	return(
		<TouchableOpacity
			onPress={props.onPress}
			activeOpacity={Config.activeOpacity}>
			<ImageIconPlus/>
    </TouchableOpacity>
	)

}

const IconMinus = (props)=>{
	if(props.disabled === true){
		return(
			<View style={{opacity: 0.5}}>
				<ImageIconMinus/>
			</View>
		)
	}

	return(
		<TouchableOpacity
			onPress={props.onPress}
			activeOpacity={Config.activeOpacity}>
			<ImageIconMinus/>
    </TouchableOpacity>
	)
}

const BoxQuantity = (props)=>{
	return(
		<View style={styleHelper.flexRowSpaceBetween}>
			<IconMinus onPress={props.onPressMinus} />
			<View style={[styleHelper.inputBorderRadius, styleHelper.bgWhite, styleHelper.containerNumber ]}>
				<Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.fontBlack, styleHelper.font14, styleHelper.textCenter]}>
					{props.modifier.quantity}
				</Text>
			</View>
			<IconPlus onPress={props.onPressPlus} disabled={props.disabled}/>
		</View>
	)
}

const ImageIconPlus = ()=>{
	return(
		<Image 
      source={require('../img/ic_add_black.png')}
      style={styleHelper.bitsmallIconSize}
      resizeMode={'contain'}/>
	)
}

const ImageIconMinus = ()=>{
	return(
		<Image 
      source={require('../img/ic_minus_black.png')}
      style={styleHelper.bitsmallIconSize}
      resizeMode={'contain'}/>
	)
}

const TouchableRadio = (props)=>{
  let active = require('../img/radiobutton_active_dark.png');
  let inactive = require('../img/radiobutton_inactive_dark.png');

	let renderRadio = (selected)=> {
		if(selected === true) {
			return(
				<Image
					source={active}
					style={styleHelper.smallIconSize}
					resizeMode={'contain'}/>
			)
		}

		return(
			<Image
				source={inactive}
				style={styleHelper.smallIconSize}
				resizeMode={'contain'}/>
		)

	}

  if(props.disabled) {
    return (
      <View>
        {renderRadio(props.selected)}
      </View>
    )
  }

	return(
		<TouchableOpacity
			activeOpacity={1}
			onPress={()=>props.onPress(props.modifier, props.modifierGroup, props.index)}
			>

			{renderRadio(props.selected)}

		</TouchableOpacity>
	)
}

import keyMirror from 'keymirror';

export default {
	ActionTypes: keyMirror({
		AJAX_GET_STORE: null,
		SELECT_STORE: null,
		AJAX_GET_MENU: null,
		AJAX_GET_CUSTOMER_DETAILS: null,
		AJAX_GET_CUSTOMER_LATEST_POINTS: null,
		AJAX_GET_PROMOTIONS: null,
		AJAX_GET_ORDER: null,
		AJAX_GET_ORDERS: null,
		AJAX_GET_ORDERS_ALL: null, 
		AJAX_POST_ORDER: null,
		// AJAX_GET_ALL_PROMOTIONS: null,
		SET_ORDER_TYPE: null,
		SELECT_TABLE: null,
		SELECT_DATETIME_TAKEAWAY: null,
		SELECT_VARIANT: null,
		SELECT_MODIFIER: null,
		RESET_MODIFIER: null,
		SELECT_PRODUCT: null,
		SELECT_VOUCHER: null,
		// DELETE_PROMOTION: null,
		DESELECT_MODIFIER: null,
		SELECT_MODIFIER_V2: null,
		ADD_PRODUCT_TO_CART: null,
		REMOVE_PRODUCT_FROM_CART: null,
		INIT_VARIANT: null,
		SET_BOOKING_TYPE: null,
		AJAX_LOGOUT: null,
		SUBMIT_PROMO_CODE: null,
		SET_SOUPERHOLIC: null,
		UPDATE_CART_AFTER_CHANGE_STORE: null,
		RESET_CART: null,
		SET_REWARD_POINTS_IN_CART: null,
		// SET_GIFT_CARD_REWARD_POINTS_IN_CART: null,
		UPDATE_PROFILE: null,
		// SET_CART_EXPIRED: null,
		SYNC_PERSIST_STORE: null,
		CLEAR_PERSIST_STORE: null,
		SAVE_PAIR_STATUS: null,
		SET_PAYMENT_ID: null,
		SET_PAYMENT_REMARKS: null,
		SET_PAYMENT_AMOUNT: null,
		SET_PAYMENT_TRANSID: null,
		// SET_MASTERPASS_PAYMENT: null,
		// SET_MASTERPASS_PIN: null,
		AJAX_GET_BRAND_DATA: null,
		ROUTE_CHANGE: null,
		UPDATE_MENU_SECTIONS: null,
		ADD_PAYMENT_CARD: null,
		SET_TOKEN_PAYMENT_ID: null,
		SET_CART_ID: null,
		SET_CUSTOM_CART_ID: null,
		SET_GIFT_CARD: null,
		SET_FOLIO: null,
		SET_MOBILE: null,
		SET_ACTION: null,
		RESET_ACTION: null,
		SET_COUNTDOWN: null,
		SET_CART_CHANGED: null,
		INIT_MENU: null,
		SET_TSS_VOUCHER: null,
		SELECT_TSS_VOUCHER: null,
		// SET_UPSELL_PRODUCT: null,
		SET_MODIFIER_MAP: null,
		// SET_STAFF_GROUP: null
	}),
}


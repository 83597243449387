import React from 'react';
import {
  Text,
  View,
  Image,
  TouchableOpacity,
  StyleSheet
} from 'react-native';

import * as Navigation from '../Routes/Routing';

import Config from '../Utils/Config';
import Device from '../Utils/Device';
import API from '../Utils/API';

import styleHelper from '../Styles/StyleHelper';

import StoreStore from '../Stores/StoreStore';

const productStyles = StyleSheet.create({
    customBtn: {
        backgroundColor: '#ca6d4f',
        width: Device.width * 0.8,
        maxHeight: Device.getSize(50),
    },
    customBtn2: {
        backgroundColor: '#a1412b',
        width: Device.width * 0.8,
        maxHeight: Device.getSize(30),
        marginTop: Device.getSize(10),
        marginBottom: Device.getSize(40),
  },
});

export default class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.navigateMyOrder = this.navigateMyOrder.bind(this);
    this.navigateMenu = this.navigateMenu.bind(this);
    this.state = {
      orderExist: false,
    }
  }

  componentDidMount() {
    API.getOrder(StoreStore.getQRToken(), {
      success: (res)=>{
        if(res.lines.length > 0) {
          this.setState({
            orderExist: true
          })
        }
      },
      error: ()=>{
        
      }
    });
  }

  navigateMenu() {
    Navigation.navigate('Home');
  }

  navigateMyOrder() {
    Navigation.navigate('MyOrder');
  }

  render() {
    return(
        <View style={[styleHelper.backgroundImage, {backgroundColor: '#f8f5f0'}]}>
            <View style={[styleHelper.containerTopFit, styleHelper.flexCenter]}>
                <Image
                  source={require('../Img/ic_logo.png')}
                  resizeMode={'contain'}
                  style={[{width: Device.getSize(200), height: Device.getSize(140)}]} />

                {/* <Text style={[styleHelper.OrangeDarkFont, styleHelper.font18, styleHelper.fontBold, styleHelper.flexCenter, {marginBottom: Device.getSize(5)}]}>
                    Anna's
                </Text> */}

                <Text style={[styleHelper.OrangeDarkFont, styleHelper.font14, styleHelper.fontHelveticaNeueRegular, styleHelper.flexCenter]}>
                    {StoreStore.getSelectedStore().address.line1}
                </Text>

                <Text style={[styleHelper.fontBlack, styleHelper.font16, styleHelper.fontBold, styleHelper.flexCenter, {marginTop: Device.getSize(40)}]}>
                    {StoreStore.getTableNo()}
                </Text>
            </View>

            <View style={[styleHelper.flexCenter]}>
                <TouchableOpacity
                    onPress={()=>{this.navigateMenu()}}
                    activeOpacity={Config.activeOpacity} 
                    style={[productStyles.customBtn, styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter]}>

                    <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.fontWhite, styleHelper.font12, styleHelper.textCenter]}>
                        {this.state.orderExist ? 'ADD ORDER' : 'START ORDER'}
                    </Text>

                </TouchableOpacity>

                <TouchableOpacity
                    onPress={()=>{this.navigateMyOrder()}}
                    activeOpacity={Config.activeOpacity} 
                    style={[productStyles.customBtn2, styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter]}>

                    <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.fontWhite, styleHelper.font12, styleHelper.textCenter]}>
                        MY ORDER
                    </Text>

                </TouchableOpacity>
            </View>
        </View>
    );
  }
}

import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';

const OrderActions = {
  selectVariant(variant){
    AppDispatcher.dispatch({
      type: Constants.ActionTypes.SELECT_VARIANT,
      variant: variant
    })
  },
  selectModifier(modifier){
    AppDispatcher.dispatch({
      type: Constants.ActionTypes.SELECT_MODIFIER,
      modifier: modifier
    })
  },
  deselectModifier(modifier){
    AppDispatcher.dispatch({
      type: Constants.ActionTypes.DESELECT_MODIFIER,
      modifier: modifier
    })
  },
  selectModifierV2(modifier, modifierGroup){
    AppDispatcher.dispatch({
      type: Constants.ActionTypes.SELECT_MODIFIER_V2,
      modifier: modifier,
      modifierGroup: modifierGroup
    })
  },
  resetModifierGroup(modifierGroup){
    AppDispatcher.dispatch({
      type: Constants.ActionTypes.RESET_MODIFIER,
      modifierGroup: modifierGroup
    })
  },
  selectProduct(product){
    AppDispatcher.dispatch({
      type: Constants.ActionTypes.SELECT_PRODUCT,
      product: product,
    })
  },

  //Used after tapping on Update cart/Next in OrderMap
  setOrderType(orderType){
    AppDispatcher.dispatch({
      type: Constants.ActionTypes.SET_ORDER_TYPE,
      orderType: orderType,
    })
  }
}

export default OrderActions;

import API from '../Utils/API';
import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';
import assign from 'object-assign';
import {EventEmitter} from 'events';
import Services from '../Utils/Services';

import CartStore from './CartStore';
import StoreStore from './StoreStore';

let _data = {};
let _productTypeAttr = null; // takeAway||dineIn
let _type = null; // takeaway||dinein

let _sections = {}; //used to display products in MenuDetails.js, data is grouped into Accordion
let _categories = [];
let _modifierMap = [];
let _brand = {};

// const emptyFunc = ()=>{};
const CHANGE_EVENT = 'change';

let _getData = (storeID, callback)=>{
  API.getCategory({
    success: (result)=>{
      _data.categories = result;
      _sortCategories();
      _sortSubCategories();

      API.getMenu(storeID, {
        success: (res)=>{
          _data.products = res.product_template_ids;
          _sortProducts();
          _sortProductsModifiers();

          // assign products into categories
          _initMenuSections(result);

          if (typeof callback === 'function') {
            callback();
          }
          MenuStore.emitChange();
        },
        error: (error)=>{
          Services.showAlertError(error);
        }
      });
    },
    error: (error)=>{
    	Services.showAlertError(error);
    }
  });
};

const _initMenuSections = (categories)=>{
  _sections = {};
  _categories = [];
  categories.forEach((category)=>{
    let section = _getMenuSections(category, categories);
    if(section.productNoSubCategory.length > 0 || section.subcategories.length > 0) {
      _sections[category.id] = section;
      // Only take categories which have products
      _categories.push(category);
    }
  });
}

const _setModifierMapping = (modifierMap, callback)=>{
  _modifierMap = modifierMap;
}

const _updateMenuSections = (callback) => {
  for (var key in _sections) {
      if (_sections.hasOwnProperty(key)) {
         _updateQuantityForProductInArray(_sections[key].productNoSubCategory);
         _sections[key].subcategories.forEach((category) => {
            _updateQuantityForProductInArray(category.products);
         })
      }
    }
  if (callback) {
    callback()
  }
}

const _updateQuantityForProductInArray = (productArray) => {
  let serversProducts = CartStore.getServerProducts();
  if (productArray) {
    productArray.forEach(product => {
      //Reset quantity
      product.quantity = 0;
      serversProducts.forEach(serverProduct => {
        if (product.id === serverProduct.product) {
          product.quantity = CartStore.getProductQuantity(product.id);
        }
      })
    })
  }
}
// Put products into a collapsable section based on categoryID
/*
  {
    productNoSubCategory: [{product}]
    subcategories: [{
      id: 40,
      imageID:
      name:
      products: [{product}]
    }]
  }
*/
const _getMenuSections=(category, categories)=>{
  let subcategoryIDs = [];
  
  if(category.child_id && category.child_id.length > 0){
    subcategoryIDs=category.child_id.map((subcategory)=>subcategory.id);

    // 3rd level category - currently not using
  //   category.children.forEach((subcategory) => {
  //     if(subcategory.children && subcategory.children.length > 0) {
  //       _getMenuSections(subcategory);
  //     }
  //   });
  }

  let {productNoSubCategory, subcategories} = _addProductsToCategories(category, subcategoryIDs);

  subcategories = Services.convertObjectToArray(subcategories);
  subcategories = _removeEmptySubcategories(subcategories);
  /** Custom By Rian
  * category available time
  * product OOS
  * modifier OOS
  */
  subcategories = _disableUnavailableSubcategories(subcategories, categories);
  subcategories = _hideUnavailableSubcategories(subcategories, categories);
  // subcategories = _disableUnavailableModifiers(subcategories, categories);

	return{
    productNoSubCategory,
    subcategories: subcategories.sort((a, b) => a.sequence - b.sequence)
  }
}

const _addProductsToCategories = (selectedCategory, subcategoryIDs)=>{
  let categoryID;
  let category;
  let productNoSubCategory = [];
  let subcategories = Services.convertArrayToObject(selectedCategory.child_id, 'id');
  let storeId = StoreStore.getSelectedStore().id;
  let available = false;
  let oos = false;
  let z = 0;

  _data.products.forEach((product, i)=>{
    categoryID = null;
    category = null;

    if(product['dine_in'] == true || product['take_away'] == true) { // here filter order type
      // check if available
      available = false;
      product.oos_shop_ids.forEach(shop_id => {
        if(shop_id.id == storeId) {
          available = true;
        }
      });

      // check if oos
      oos = false;
      if(product.product_variant_ids.length >= 1) {
        z = 0;
        product.product_variant_ids.forEach(variant => {
          variant.oos_store.forEach(shop_id => {
            if(shop_id.id == storeId) {
              z += 1;
            }
          });
        });

        if(z == product.product_variant_ids.length) {
          oos = true;
        }
      } else {
        oos = true;
      }

      if(available && !oos) {
        _addCartQuantityToProduct(product);
  
        product.category_ids.map((c)=>{
          if(c.id == selectedCategory.id) {
            productNoSubCategory.push(product);
          }
        });
  
        product.category_ids.map((c)=>{
          if(subcategoryIDs.includes(c.id)) {
            categoryID = c.id;

            if(categoryID) {
              category = subcategories[categoryID];
              if(category.products){
                category.products.push(product);
              } else {
                category.products = [product];
              }
            }
          }
        });
      }
    }
  })

  return{
    productNoSubCategory,
    subcategories
  }
}

// add product.quantity in regard of cart
const _addCartQuantityToProduct = (product)=>{
  product.quantity = CartStore.getProductQuantity(product.id);
}

const _removeEmptySubcategories = (subcategories)=>{
  let _subcategories = [];
  let _children = [];
  if(subcategories.length > 0) {
    subcategories.forEach((subcategory) => {
      if(subcategory.child_id && subcategory.child_id.length > 0) {
        // remove children
        _children = [];
        subcategory.child_id.forEach((child)=>{
          if((child.child_id && child.child_id.length > 0)
            || (child.products && child.products.length > 0) ){
            _children.push(child);
          }
          subcategory.child_id = _children;
        })
        _subcategories.push(subcategory);
      } else if(subcategory.products && subcategory.products.length > 0) {
        _subcategories.push(subcategory);
      }
    });  
  }
  return _subcategories;
  // return subcategories.filter((subcategory)=>subcategory.products);
}

// TO BE CHANGED
const _disableUnavailableSubcategories = (subcategories, categories)=>{
  let _subcategories = [];
  let category = [];
  if(subcategories.length > 0) {
    subcategories.forEach((subcategory) => {
      category = categories.filter((category) => category.id == subcategory.id)[0];

      // category available time
      let isAvail = false;
      let startTime = 0;
      let endTime = 0;
      if(category.pos_category_day_ids.length > 0) {
        let today = new Date();
        let dayname = today.toLocaleDateString('en-us', { weekday: 'long' });
        let hour = today.getHours();
        let min = today.getMinutes();
        let time = hour + (min / 60);
        category.pos_category_day_ids.forEach(activeTime => {
          if(activeTime.name == dayname.toLowerCase()) {
            startTime = activeTime.start_time;
            endTime = activeTime.end_time;
            if(activeTime.start_time <= time && activeTime.end_time > time) {
              isAvail = true;
            }
            return;
          }
        });
      } else {
        isAvail = true;
      }

      // disable product order when not in available time
      if(subcategory.products) {
        if(isAvail) {
          subcategory.products.forEach((product)=>{
            product.available_for_order = true;
          });
        } else {
          let start_hour = Math.floor(startTime);
          let start_mins = (startTime % 1) * 60;
          let end_hour = Math.floor(endTime);
          let end_mins = (endTime % 1) * 60;

          subcategory.products.forEach((product)=>{
            product.available_for_order = false;
            product.err_msg = 'Item is only available from ' + ('0' + start_hour).slice(-2) + '.' + ('0' + start_mins).slice(-2) + ' - ' + ('0' + end_hour).slice(-2) + '.' + ('0' + end_mins).slice(-2);
          });
        }
      }

      _subcategories.push(subcategory)
    });
  }
  return _subcategories;
}

// TO BE CHANGED
const _hideUnavailableSubcategories = (subcategories, categories)=>{
  let storeId = StoreStore.getSelectedStore().id;
  let _subcategories = [];
  let category = [];
  let _products = [];
  if(subcategories.length > 0) {
    subcategories.forEach((subcategory) => {
      category = categories.filter((category) => category.id == subcategory.id)[0];

      // category available time
      let isAvail = false;
      if(category.pos_category_day_ids.length > 0) {
        let today = new Date();
        let dayname = today.toLocaleDateString('en-us', { weekday: 'long' });
        let hour = today.getHours();
        let min = today.getMinutes();
        let time = hour + (min / 60);
        category.pos_category_day_ids.forEach(activeTime => {
          if(activeTime.name == dayname.toLowerCase()) {
            if(activeTime.start_time <= time && activeTime.end_time > time) {
              isAvail = true;
            }
            return;
          }
        });
      } else {
        isAvail = true;
      }

      // disable product order when not in available time
      if(subcategory.products) {
        // hide oos product
        _products = [];
        subcategory.products.forEach(product => {
          product.oos_shop_ids.forEach(shop_id => {
            if(shop_id.id == storeId) {
              _products.push(product);
            }
          });
        });
        subcategory.products = _products;

        if(isAvail) {
          _subcategories.push(subcategory)
        }
      }
    });
  }
  return _subcategories;
}

const _disableUnavailableModifiers = (subcategories, categories)=>{
  let storeId = StoreStore.getSelectedStore().id;
  let _subcategories = [];
  if(subcategories.length > 0) {
    subcategories.forEach((subcategory) => {
      if(subcategory.products) {
        subcategory.products.forEach(product => {
          product.product_variant_ids.forEach(variant => {
            variant.modifier_group_line_ids.forEach(modifier_group => {
              modifier_group.product_modifier_ids.forEach(product_modifier => {
                product_modifier.is_available = false;
                product_modifier.product_id.oos_shop_ids.forEach(oos_shop => {
                  // TO BE CHANGED
                  if(oos_shop == storeId) {
                    product_modifier.is_available = true;
                  }
                })
              })
            })
          })
        });
      }

      _subcategories.push(subcategory)
    });
  }
  return _subcategories;
}

const _sortCategories = ()=>{
  _data.categories.sort((a, b)=>a.sequence - b.sequence);
}

const _sortSubCategories = ()=>{
  _data.categories.forEach((category)=>{
    category.child_id.sort((a, b)=>a.sequence - b.sequence);
  })
}

const _sortProducts = ()=>{
  // _data.products.sort((a, b)=>a.product_sequence - b.product_sequence);
  _data.products.sort((a, b)=>a.product_variant_ids[0].product_sequence - b.product_variant_ids[0].product_sequence);
}

const _sortProductsModifiers = ()=>{
  _data.products.forEach((product)=>{
    // product.modifierGroups.forEach((modifierGroup)=>{
    //   modifierGroup.modifiers.sort((a,b)=>a.sortIndex - b.sortIndex);
    // });

    product.product_variant_ids.forEach((variant)=>{
      variant.modifier_group_line_ids.sort((a,b)=>a.sequence - b.sequence);
    })

    product.product_variant_ids.forEach((variant)=>{
      variant.modifier_group_line_ids.forEach((modifierGroup)=>{
        modifierGroup.product_modifier_ids.sort((a,b)=>a.sequence - b.sequence);
      })
    })
  })
}

let MenuStore = assign({}, EventEmitter.prototype, {
    getCategory(categoryID){
        return _categories.filter((category)=>category.id===parseInt(categoryID))[0];
    },
    getMultipleCategories(categoryIDs){
        let categories = [];
        let category;
        let catIDs;
        if(Number.isInteger(categoryIDs)){
            category = _categories.filter((category)=>category.id===parseInt(categoryIDs))[0];
            if(category !== undefined) {
                categories.push(category);
            }
        }
        else
        {
            catIDs = categoryIDs.split(',');
            catIDs.forEach((categoryID, index)=>{
                category = _categories.filter((category)=>category.id===parseInt(categoryID))[0];
                if(category !== undefined) {
                    categories.push(category);
                }
            });
        }
        categories.sort((a, b)=>a.sequence - b.sequence);
        return categories;
    },
    getMenuDetailsSections() {
        return _sections;
    },
    getCategories(){
        return _categories;
    },
    getCategoriesV2(){
        return _data.categories;
    },
    getBrand(){
        return _brand;
    },
    checkProductAvailability(productId, type){
        const filteredProducts = _data.products.filter(product => {
            if (product.id === productId) {
                switch(type) {
                    case 'takeaway':
                        return product.take_away;
                    case 'dinein':
                        return product.dine_in;
                }
            }
            return false;
        })

        return filteredProducts.length > 0;
    },
    checkCartProductsAvailability(){
        var productsNotAvailable = [];
        const cartProducts = CartStore.getServerProducts();
        const storeType = StoreStore.getType();
        cartProducts.forEach(product => {
            if (this.checkProductAvailability(product.product, storeType) === false) {
                productsNotAvailable.push(product.name);
            }
        })
        if (productsNotAvailable.length > 0) {
            Services.showAlertError(`item ${productsNotAvailable.join(', ')} is no longer available`);
            return false
        }

        return true
    },
    setType(type){
        _type = type.toLowerCase();
        _productTypeAttr = Services.parseOrderType(_type);
    },
    emitChange(){
        this.emit(CHANGE_EVENT);
    },
    addChangeListener(callback){
        this.on(CHANGE_EVENT, callback);
    },
    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },
    getAllProducts() {
        return _data.products;
    },
    getModifierMap() {
        return _modifierMap;
    },
})

MenuStore.dispatchData = AppDispatcher.register((action)=>{
	switch(action.type) {
    case Constants.ActionTypes.AJAX_GET_MENU:
      _getData(action.storeID, action.callback);
      break;
    case Constants.ActionTypes.UPDATE_MENU_SECTIONS:
      _updateMenuSections(action.callback);
      break;
    case Constants.ActionTypes.SET_ORDER_TYPE:
      MenuStore.setType(action.orderType);
      break;
    case Constants.ActionTypes.INIT_MENU:
      _initMenuSections(action.categories);
      break;
    case Constants.ActionTypes.SET_MODIFIER_MAP:
      _setModifierMapping(action.modifierMap, action.callback);
      break;
  }
})

export default MenuStore;

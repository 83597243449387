import API from '../Utils/API';
import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';
import assign from 'object-assign';
import {EventEmitter} from 'events';

import Services from '../Utils/Services';
import Storage from '../Utils/Storage';
import Config from '../Utils/Config';

// import PromotionStore from '../Stores/PromotionStore';
// import CustomerStore from '../Stores/CustomerStore';
import OrderStore from '../Stores/OrderStore';
import StoreStore from '../Stores/StoreStore';

const emptyFunc = ()=>{};

const MESSAGE_ERROR_VOUCHERS_GRAND_TOTAL_ZERO = 'The grand total is 0.\nVouchers cannot be applied.';
const MESSAGE_ERROR_PROMO_CODE_GRAND_TOTAL_ZERO = 'The grand total is 0.\nPromo codes cannot be applied.';
const MESSAGE_ERROR_REWARD_POINTS_GRAND_TOTAL_ZERO = 'The discount money greater than grand total.';

let _data = {
	products: [], //selected products
	displayProducts: [], // products are used to submitted to server
	/*
		{
			id: Number,
			name: String
			price: Number
			variant: null || {
				id: Number,
				name: String,
				price: Number,
			}
			modifiers: [{
    
      }],

		}
	*/
  serverProducts: [], //products are used to show on UI, get from server
  /* 
    id: 0
    modifiers: [{
      id: 0
      modifierId: 351
      modifierName: "Ribeye beef partty"
      modifiers: []
      price: 7.5
      quantity: 1
    }]
    product: 70
    productName: "Ultimate Chicken"
    quantity: 1
    specialRequest: null
    total: 27.4
    variant: null
    variantName: null
    variantPrice: null
  */

  extraCharges: [],
  /* 
    {
      amount: 2.09
      comment: null
      id: 0
      inclusive: true
      name: "GST"
      percentage: 7
      string: "GST"
      tax: true 
      type: "GST"
    }
  */

  store: {},
  promotions: {
    vouchers: [/*ids*/],
    promoCodes: [/*codes*/],
  },
  discounts: [{
    id: 0,
    amount: 0,
    reason: '',
    discountId: 0
  }],
}

let _grandTotal = null;
let _subtotal = null;
let _isAvailable = null;
let timeoutResetCart;
let _payments = null;
let _discounts=[]; //Discount from server
let _fulfillmentTime = null; // Datetime to collect Order in Takaway, unit is Seconds

let _discountsDisplay = [];
let _discountsDisplayPromoCode = []; //Used in Cart screen, the discount text below Promo Code Input 
let _discountsDisplayVouchers = []; //Used in Cart screen, the discount text below Vouchers picker 

let _order = {}; // Order object from server

/*
  [{
    type: 'amount', // string: 'percentage' || 'amount'
    value: 0,
  }]
*/
let _paymentID; //used for credit card payment, (new Date()).getTime()
let _masterpassPaymentObject; //used for masterpass payment
let _orderType; //takeaway/dinein
let _orderAttrType; // get from Services.parseOrderType(_orderType);
let _rewardPoints = 0; // user's souperholic points
let _giftCardRewardPoints = 0; // user's gift card points
let _orderNumber = '';
let _paymentRemarks = '';
let _paymentAmount = 0;
let _paymentTransId = '';
let _cartID; // unique cart ID generated by Eunoia API
let _payCartID; // unique cart ID used for payment
let _isCartChanged = false;
let _cartId = null;

// TSS Voucher
let _selectedTssVoucher = 0;

/*
  all promotions from API /promotions/
*/
let _promotionsAll = []; 


let _isAvailableForCreditCardPayment = true; 


const CHANGE_PRODUCT_EVENT = 'change_product',
      POST_ORDER = 'post_order',
      EXPIRE_CART = 'expire_cart',
      SUCCESSFUL_PAYMENT = 'successful_payment',
      //TIME_RESET_CART = 5000;
      TIME_RESET_CART = 900000;

let _authToken;

//Update _data.products after editing
const _updateProduct = (updatedProduct, qty)=>{
	let {position, result} = Services.findObjectInArray(_data.products, {cartID: updatedProduct.cartID});
  let products = _data.products.filter((product)=>product.cartID !== updatedProduct.cartID);
  updatedProduct.productUniqueId = CartStore.setProductUniqueId(updatedProduct);
  updatedProduct.quantity = qty;

  if(position!==null){
    if(products.length > 0){
      products.forEach((product,i)=>{
        if(product.productUniqueId === updatedProduct.productUniqueId){
          // Merge existing products
          product.quantity += updatedProduct.quantity;
          _data.products = products;
        }
        else
        {
          // Replace updated product
          _data.products[position] = updatedProduct;
        }
      });
    } else {
      // Only 1 item in cart, Replace updated product
      _data.products[position] = updatedProduct;
    }
	}
}

// Assign product.modifierGroups to product.variants if product.variants is empty
// const _checkProductVariants = (product)=>{
// 	if(!product.variants || product.variants.length === 0){
// 		product.variants = [{modifierGroups: product.modifierGroups, isAdded: true}];
// 	}
// }

const _souperholicPointPayment = () => {
  
  if (!_payments) {
    return null;
  }

  const pointPayments = _payments.filter((payment) => {
    return payment.type === 'POINTS';
  });

  if (pointPayments.length > 0) {
    var pointPayment = pointPayments[0];
    pointPayment.status = 'PAID';
    pointPayment.paidTime = (new Date).getTime();

    return pointPayment;
  }
  return null;
}

const _postOrder = (store, promotions, errorCallback, isRedemption = false)=>{
  _data.store=store;
  _clearOrderBeforePost();
  Services.showSpinner();

  let order = _getData();

  API.postOrder({
    jsonrpc: '2.0',
    params: {
      order
    }
  },
    {
      success: (res)=>{
        order.subtotal = res.result.subtotal;
        order.total = res.result.total;
        _order = order;
        CartStore.resetProductServer();

        let serverProducts = _assignCartIDToServerProducts(order.items);

        _data.extraCharges = [{
          string: 'Service Charge',
          percentage: 10,
          amount: res.result.service_charge
        },
        {
          string: 'GST',
          percentage: Config.gst,
          amount: res.result.gst
        }];
        _data.serverProducts = serverProducts;
        _updateProductFromServer(serverProducts);

        _grandTotal = order.total;
        _subtotal = order.subtotal;
        _isAvailable = true;
        _setDiscount(order.discounts);
        _setTimeoutClearCart();
        // _fulfillmentTime = order.fulfillmentTime;

        CartStore.emitChangeProductCart();

        _paymentID = null;
        _paymentRemarks = null;
        // _masterpassPaymentObject = null;
        _cartID = (new Date).getTime();
        _payCartID = null;
      },
      error: (error)=>{
        if(errorCallback) {
          _paymentID = null;
          _paymentRemarks = null;
          _masterpassPaymentObject = null;
          _cartID = null;

          // if(error.vouchers && error.vouchers.length > 0){
          //   Services.showAlertError(error.vouchers.map((voucher)=>`Voucher ${voucher.name}: ${voucher.message}`), ()=>{
          //       _removeInvalidPromotions(error.vouchers.map((voucher)=>voucher.id), _data.promotions.vouchers, 'VOUCHERS');
          //     });

          // } else {
          //   if(error.promoCodes && error.promoCodes.length > 0){
          //     Services.showAlertError(error.promoCodes.map((code)=>`Promo code ${code.code.toUpperCase()}: ${code.message}`), ()=>{
          //       _removeInvalidPromotions(error.promoCodes.map((promoCode)=>promoCode.code.toLowerCase()), _data.promotions.promoCodes, 'PROMO_CODE');
          //     });
          //   } else {
              Services.showAlertError(error, () => {
                  Storage.Navigator.resetTo({
                      // name: 'Cart',
                      name: 'Menu',
                      hasPrevRoute: false
                  })
              });
        //     }
        //   }
        }
      },
      complete: ()=>{
        if(errorCallback) {
          Services.hideSpinner();
        }
      }
    }
  )
}

const _clearOrderBeforePost = ()=>{
  _fulfillmentTime = null;
}

const _assignCartIDToServerProducts = (serverProducts)=>{
  let id = 1;
  serverProducts.forEach((serverProduct)=>{
    serverProduct.cartID = id;
    // Added by rian: assign plu code to server products
    let product = _data.products.filter((product)=>product.id === serverProduct.product)[0];
    if(product){
      serverProduct.pluCode = product.pluCode;
    }
    id++;
  });
  return serverProducts;
}

// get variants, modifiers from server product and set to cart products
const _setModifiersFromServerProduct = (product, serverProduct)=>{
  let modifierGroups = [];

  if(Number.isFinite(serverProduct.variant)){
    product.product_variant_ids.forEach((variant)=>{
      if(variant.id === serverProduct.variant){
        modifierGroups = variant.modifier_group_line_ids;
        variant.isAdded = true;
      } else {
        variant.isAdded = false;
      }
    })  
  } else {
    modifierGroups = product.product_variant_ids[0].modifier_group_line_ids;
  } 
  
  _setAddedModifiersFromServerProduct(modifierGroups, serverProduct.modifiers);

  return product;
}

const _setAddedModifiersFromServerProduct = (modifierGroups, serverModifiers)=>{
  /*
    _ Assign modifier quantity from server
  */
  if(serverModifiers.length > 0){
    let serverModifierIDs = serverModifiers.reduce((arr, modifier)=>{arr.push(modifier.modifierId); return arr;}, []);
    let serverQuantityModifiers = serverModifiers.reduce((obj, modifier)=>{obj[modifier.modifierId] = modifier.quantity; return obj;}, {});

    modifierGroups.forEach((modifierGroup)=>{
      modifierGroup.product_modifier_ids.forEach((modifier)=>{
        if(serverModifierIDs.includes(modifier.id)){
          modifier.quantity = serverQuantityModifiers[modifier.id];
        } else {
          modifier.quantity = 0;
        }
      })
    })
  }
}

//replace _data.products with products from server
const _updateProductFromServer = (serverProducts)=>{
  let products = [];
  let uid = '';
  serverProducts.forEach((serverProduct)=>{
    uid = serverProduct.product.toString();
    if(serverProduct.variant){
      uid += serverProduct.variant.toString();
    }
    serverProduct.modifiers.forEach((modifier,i)=>{
      uid += modifier.modifierId.toString();
      uid += modifier.quantity.toString();
    });
    let product = _data.products.filter((product)=>product.productUniqueId === uid)[0];
    if(product){
      product = Services.cloneObject(product);
      product = _setModifiersFromServerProduct(product, serverProduct);
      product.cartID = serverProduct.cartID;
      products.push(product);
    }
  });
  _data.products = products;
}

const _resetTotal = ()=>{
  _subtotal = null;
  _grandTotal = null;
  _data.extraCharges = [];
}

// Reset cart after 15 minutes
const _setTimeoutClearCart = ()=>{
  clearTimeout(timeoutResetCart);
  timeoutResetCart = setTimeout(()=>{
    CartStore.resetCart();
    CartStore.emitExpireCart();
  }, TIME_RESET_CART);
}

const _setDiscount = (discounts)=>{
  
  if(discounts && discounts.length > 0) {
    if(discounts[0].amount > 0) {      
      _discounts = discounts;
      _discountsDisplay = Services.getDiscountDisplay(discounts);
      _filterDiscountsDisplay(_discountsDisplay);
    } else {
      _discounts = [];
      _resetDiscountDisplay();  
    }
  } else {
    _discounts = [];
    _resetDiscountDisplay();
  }
}


const _selectVoucher = (voucherID)=>{
  if(_grandTotal <= 0){
    setTimeout(()=>{
      Services.showAlertError(MESSAGE_ERROR_VOUCHERS_GRAND_TOTAL_ZERO);
    }, 200);
  } else {
    if(_data.promotions.vouchers.includes(voucherID) === false){
      let vouchers = _data.promotions.vouchers.slice(0);
      vouchers.push(voucherID);

      let  promotions = {
        promoCodes: _data.promotions.promoCodes,
        vouchers: vouchers,
      };

      _postOrder(_data.store, promotions, true);
    }
  }
}

const _getData = () => {
  let store = OrderStore.getData();
  let data = {
      type: 'DINE_IN',
      pricelist_id: StoreStore.getPriceListId(),
      session_id: parseInt(StoreStore.getSessionId()),
      items: [],
      store: store.store.id,
      test: true,
      draft: true,
      origin: 'Web'
  }
  switch(store.type){
      // case 'takeaway':
      //     data.type = 'TAKE_AWAY';
      //     data.fulfillmentTime = store.epochTime;
      //     break;
      case 'dinein':
          data.type = 'DINE_IN';
          data.table = store.tableId;
          break;
      // default:
      //     data.type = 'TEST'
  }
  
  //Add products
  let products = CartStore.getDisplayProducts();
  products.forEach(product => {
    let orderProduct = {
      product: product.id,
      quantity: product.quantity,
      productName: product.name,
      // pluCode: product.pluCode,
      productPrice: product.price,
      modifiers: [],
    };
    if (product.variant) {
      orderProduct.variant = product.variant.id;
      orderProduct.variantName = product.variant.name;
      // orderProduct.variantPluCode = product.variant.pluCode;
      orderProduct.variantPrice = product.variant.price;
    }
    product.modifiers.forEach(modifier => {
      orderProduct.modifiers.push({
        modifierId: modifier.id,
        quantity: modifier.quantity,
        modifierGroupName: modifier.groupName,
        modifierName: modifier.product_id.name,
        // pluCode: modifier.plu_code,
        price: modifier.price
      });
    });
    data.items.push(orderProduct);
  });

  return data;
}

function isJson(res) {
  try{
    JSON.parse(res)
  } catch(e) {
    return false;
  }
  return true;
}

// const _selectTssVoucher = (voucherID, voucherCode)=>{
// //   CartStore.resetTssDiscount();

// //   // this.amount = CartStore.getGrandTotal();
// //   // this.amount = parseFloat(this.amount.toFixed(2));
// //   let voucher = CustomerStore.getTssVoucher(voucherID);
// //   let param = {
// //       posStoreCode: StoreStore.getPosStoreCode(),
// //       data: _getData(),
// //       subtotal: CartStore.getSubtotal(),
// //       voucherCode: voucherCode,
// //       // extraCharges: CartStore.getExtraCharge(),
// //       serverProducts: CartStore.getServerProducts(),
// //       cardType: CustomerStore.getCardType(),
// //       cardNumber: CustomerStore.getCardNumber(),
// //       // amount: this.amount,
// //   };

// //   API.ApplyVoucher(param, (res)=>{
// //     if(isJson(res)){
// //       let result = JSON.parse(res);
// //       _data.discounts = [{
// //         id: voucherID,
// //         amount: param.subtotal - result.AmountAfterDiscount,
// //         reason:  voucher.VoucherName,
// //         voucherCode:  result.VoucherCode,
// //         discountId: voucher.DiscountId
// //       }];
// //       _postOrder(_data.store, _data.promotions, true);
// //     } else {
// //       // delay to show properly
// //       setTimeout(() => {
// //         Services.showAlertError(res[0]);
// //       }, 1000);
// //     }
// //   });
// }

// const _deletePromotion = (promotionID, voucherID)=>{
//   // let promoCodes = _data.promotions.promoCodes.slice(0).map(code=>code.toLowerCase()),
//       // vouchers = _data.promotions.vouchers.slice(0);
//   let promoCodes = _data.promotions.promoCodes.map(code=>code.toLowerCase()),
//       vouchers = _data.promotions.vouchers;

//   if(voucherID) {
//     Services.removeArrayElement(vouchers, voucherID);
//   } else if(promotionID) {
//     let {position, result} = Services.findObjectInArray(_promotionsAll, {id: promotionID});

//     Services.removeArrayElement(promoCodes, result.code.toLowerCase());
//   }

//   let promotions = {
//     promoCodes,
//     vouchers,
//   };

//   _data.discounts = [{
//     id: 0,
//     amount: 0,
//     reason: '',
//     voucherCode: '',
//     discountId: 0
//   }];

//   _postOrder(_data.store, promotions, true);
// }


// const _addPromoCode = (promoCode, auto)=>{
//   if(_grandTotal <= 0){
//     Services.showAlertError(MESSAGE_ERROR_PROMO_CODE_GRAND_TOTAL_ZERO);
//   } else {
//     if(_data.promotions.promoCodes.includes(promoCode) === false){
//       if(_data.discounts[0].amount > 0) {
//         Services.showAlertError('Promo code can\'t be used along with voucher.');
//       } else {
//         let promoCodes = _data.promotions.promoCodes.slice(0);
//         promoCodes.push(promoCode);
//         let promotions = {
//           promoCodes: promoCodes,
//           vouchers: _data.promotions.vouchers,
//         };

//         _postOrder(_data.store, promotions, true);
//       }
//     } else {
//       if(auto == false){
//         Services.showAlertError('This promo code is already used.');
//       }
//     }
//   }
// }

const _resetPromotions = ()=>{
  _data.promotions = {
    vouchers: [],
    promoCodes: [],
  }

  _data.discounts = [{
    id: 0,
    amount: 0,
    reason: '',
    voucherCode: '',
    discountId: 0
  }];
}

const _removeUnavailableProductsWithStore = (store, callback)=>{
  Services.showSpinner();

  API.getMenu(store.store.id, {
    success: (res)=>{
      let newProducts = res.products.filter((product)=>product[_orderAttrType] === true);
      let newProductIds = newProducts.map((product)=>product.id);

      _data.products = _data.products.filter((product)=>newProductIds.includes(product.id));

      CartStore.updateDisplayProducts();
      
      (callback.success || emptyFunc)();
    },
    error: (error)=>{
      Services.showAlertError(error);
      (callback.error || emptyFunc)();
    },
    complete: ()=>{
      Services.hideSpinner();
      (callback.complete || emptyFunc)();
    }
  })
}

const _setOrderType = (type)=>{
  _orderType = type;
  _orderAttrType = Services.parseOrderType(type);
}

const _setRewardPoints = (points)=>{
  _rewardPoints = points;
}

const _setGiftCardRewardPoints = (points)=>{
  _giftCardRewardPoints = points;
}

const _addCartMarkToModifiers = (variant)=>{
  variant.modifier_group_line_ids.forEach((modifierGroup)=>{
    modifierGroup.product_modifier_ids.forEach((modifier)=>{
      modifier.isAddedToCart = true;
    })
  })
}

const _setVariantAddedToCart = (product)=>{
  if(product.product_variant_ids && product.product_variant_ids.length > 0){
    product.product_variant_ids.forEach((variant)=>{
      if(variant.isAdded === true){
        _addCartMarkToModifiers(variant);
      }
    })
  } else {
    _addCartMarkToModifiers(product);
  }
}

const _checkRewardPointsWhenGrandTotalZero = ()=>{
  if(_rewardPoints && _grandTotal < 0){
    Services.showAlertError(MESSAGE_ERROR_REWARD_POINTS_GRAND_TOTAL_ZERO);
  }
}

const _filterDiscountsDisplay = (discountsDisplay)=>{
  /*
    _ Extract Vouchers & Promo Code discounts
  */
  _discountsDisplayPromoCode = [];
  _discountsDisplayVouchers = [];

  discountsDisplay.forEach((discount)=>{
    if(discount.voucher){
      _discountsDisplayVouchers.push(discount);
    } else {
      if(discount.promotion){
        _discountsDisplayPromoCode.push(discount);
      }
    }
  })
}

const _resetDiscountDisplay = ()=>{
  _discountsDisplay = [];
  _discountsDisplayPromoCode = [];
  _discountsDisplayVouchers = [];
}

const _getAllPromotions = ()=>{
  // API.getAllPromotions({
  //   success: (res)=>{
  //     _promotionsAll = res.promotions;
  //   },
  //   error: (err)=>{
  //     Services.showAlertError(err);
  //   }
  // })
}

const _removeInvalidPromotions = (invalidIDs, promotionIds, publishType)=>{
  /*
    _ promotionIds: _data.promotions.vouchers || _data.promotions.promoCodes
    _ publishType: PROMO_CODE || VOUCHERS
  */

  let promotionIdsValid = Services.removeArrayFromArray(invalidIDs, promotionIds);
  if(promotionIdsValid.length < promotionIds.length){
    switch(publishType){
      case 'PROMO_CODE': 
        _data.promotions.promoCodes = promotionIdsValid;
        break;
      case 'VOUCHERS': 
        _data.promotions.vouchers = promotionIdsValid;
        break;
    }
    _postOrder(_data.store, _data.promotions, true);
  }
}

const _cartChanged = (cartChanged)=>{
  _isCartChanged = cartChanged;
}

const _setCartId = cartId => {
  _cartId = cartId;
};

let CartStore = assign({}, EventEmitter.prototype, {
  addProductToCart(product, qty){
    if(product) {
    	if(product.cartID) {
        _updateProduct(product, qty);
        _cartChanged(false);
    	}else{
        let addProduct = Services.cloneObject(product);

        _setVariantAddedToCart(addProduct);

        addProduct.productUniqueId = this.setProductUniqueId(addProduct); // addProduct.id.toString();
        
        // //Check if product has variant
        // if(addProduct.variants){
        //   addProduct.variants.some((variant)=>{
        //     if(variant.isAdded === true){
        //       modifierGroups = variant.modifierGroups;
        //       addProduct.productUniqueId += variant.id.toString();
        //     }
        //     return variant.isAdded;
        //   })
        // }
        
        // // Choose modifierGroups of product if there is no variant
        // let modifierGroups = [];
        // if(modifierGroups.length === 0){
        //   modifierGroups = addProduct.modifierGroups;
        // }

        // modifierGroups.forEach((modifierGroup, i)=>{
        //   modifierGroup.modifiers.forEach((modifier)=>{
        //     if(modifier.quantity > 0){
        //       addProduct.productUniqueId += modifier.id.toString();
        //       addProduct.productUniqueId += modifier.quantity.toString();
        //     }
        //   });
        // });

        if(this.isNewProduct(_data.products, addProduct)) {
          // addProduct.quantity = 1;
          addProduct.quantity = qty;
          _data.products.push(addProduct);
        } else {
          _data.products.forEach((product, i)=>{
            if(product.productUniqueId === addProduct.productUniqueId){
              // product.quantity += 1;
              product.quantity += qty;
            }
          });
        }
        _cartChanged(true);
	    }
    }
    this.updateDisplayProducts();
  },

  updateDisplayProducts(){
    _data.displayProducts = [];
    let displayProducts = [];

  	_data.products.forEach((product)=>{
  		let displayProduct = {};
      let modifierGroups = [];
      
  		displayProduct = {
  			modifiers: [],
        id: product.id,
        quantity: product.quantity,
        pluCode: product.default_code,
        productUniqueId: product.id.toString(),
        name: product.name,
        price: product.list_price
  		};
      
      //Check if product has variant
      if(product.product_variant_ids){
  			product.product_variant_ids.some((variant)=>{
          if(variant.isAdded === true){
            modifierGroups = variant.modifier_group_line_ids;
            let variantName = variant.attribute_value_ids.length > 0 ? variant.attribute_value_ids[0].name : '';
            displayProduct.variant = {
              id: variant.id,
              name: variantName,
              pluCode: variant.default_code,
              price: variant.lst_price
              // pluCode: variant.pluCode
            };
            // 1 product only can choose 1 variant, replace the null plucode instead
            displayProduct.pluCode = variant.default_code;
            displayProduct.productUniqueId += variant.id.toString();
          }
  				return variant.isAdded;
  			})
      }

      // Choose modifierGroups of product if there is no variant
      // if(modifierGroups.length === 0){
      //   modifierGroups = product.modifierGroups;
      // }

      modifierGroups.forEach((modifierGroup, i)=>{
        modifierGroup.product_modifier_ids.forEach((modifier)=>{
          if(modifier.quantity > 0){
            modifier.groupName = modifierGroup.name;
            displayProduct.modifiers.push(modifier);
            displayProduct.productUniqueId += modifier.id.toString();
            displayProduct.productUniqueId += modifier.quantity.toString();
          }
        });
      });

      if(this.isNewProduct(displayProducts, displayProduct)){
        displayProducts.push(displayProduct);
      }
    });
    _data.displayProducts = displayProducts;
  },

  isNewProduct(currentProduct, newProduct){
    let product = currentProduct.filter((product)=>product.productUniqueId === newProduct.productUniqueId)[0];
    if(product){
      return false;
    } else {
      return true;
    }
  },

  setProductUniqueId(product){
    let modifierGroups = [];
    let productUniqueId = product.id.toString();

    //Check if product has variant
    if(product.product_variant_ids){
      product.product_variant_ids.some((variant)=>{
        if(variant.isAdded === true){
          modifierGroups = variant.modifier_group_line_ids;
          productUniqueId += variant.id.toString();
        }
        return variant.isAdded;
      })
    }

    // Choose modifierGroups of product if there is no variant
    // if(modifierGroups.length === 0){
    //   modifierGroups = product.modifierGroups;
    // }

    modifierGroups.forEach((modifierGroup, i)=>{
      modifierGroup.product_modifier_ids.forEach((modifier)=>{
        if(modifier.quantity > 0){
          productUniqueId += modifier.id.toString();
          productUniqueId += modifier.quantity.toString();
        }
      });
    });
    return productUniqueId;
  },

  cartChanged(){
    return _isCartChanged;
  },

  removeProduct(cartID){
  	_data.products = _data.products.filter((product)=>product.cartID !== cartID);
    this.updateDisplayProducts();
  	_data.serverProducts = _data.serverProducts.filter((product)=>product.cartID !== cartID);
    _resetTotal();

    if(_data.products.length === 0){
      _resetPromotions();
    }

    _postOrder(_data.store, _data.promotions, true);
  	this.emitChangeProductCart();
  },

  getDisplayProducts(){
  	return _data.displayProducts;
  },
  getSubtotal(){
    return _subtotal;
  },
  getSubtotalAfterDiscount() {
    let subtotalAfterDiscount = _subtotal;

    _discounts.forEach(discount => {
      //Only excludes discount that is promotion or voucher
      if (discount.promotion || discount.voucher) {
        subtotalAfterDiscount -= discount.amount;
      }
    });

    // Tss Voucher
    _data.discounts.forEach(discount => {
      //Only excludes discount that is promotion or voucher
      subtotalAfterDiscount -= discount.amount;
    });
    
    _data.extraCharges.forEach(extraCharge => {
      if(extraCharge.amount) {
        subtotalAfterDiscount += extraCharge.amount;
      }
    });

    if(_giftCardRewardPoints){
      subtotalAfterDiscount -= (_giftCardRewardPoints/100);
    }

    return Math.max(subtotalAfterDiscount, 0);
  },
  getSubtotalAfterDiscount_wo_giftcard() {
    let subtotalAfterDiscount = _subtotal;

    _discounts.forEach(discount => {
      //Only excludes discount that is promotion or voucher
      if (discount.promotion || discount.voucher) {
        subtotalAfterDiscount -= discount.amount;
      }
    });

    // Tss Voucher
    _data.discounts.forEach(discount => {
      //Only excludes discount that is promotion or voucher
      subtotalAfterDiscount -= discount.amount;
    });
    
    _data.extraCharges.forEach(extraCharge => {
      subtotalAfterDiscount += extraCharge.amount;
    });

    return Math.max(subtotalAfterDiscount, 0);
  },
  getGrandTotal(){
  	return _grandTotal < 0 ? 0:_grandTotal;
  },
  getPaymentsTotal(){
    var paymentTotal = 0;
    _payments.forEach(payment => {
      paymentTotal += payment.amount;
    })
    return paymentTotal;
  },
  getPayments() {
    return _payments;
  },
  getExtraCharge(){
    return _data.extraCharges;
  },
  getServerProducts(){
    return _data.serverProducts;
  },
  getQuantity(){
    if(_data.serverProducts) {
      let qty = 0;
      _data.serverProducts.forEach(product => {
        qty += product.quantity;
      });
      return qty;
    }
    return 0;
  },
  getProductQuantity(productID){
    let foundProducts = _data.serverProducts.filter((product)=>product.product === productID);
    return foundProducts.length;
  },
  getDiscountsDisplay(){
    return _discountsDisplay;
  },
  getDiscountsDisplayPromoCodes(){
    // let promo = null;
    // let promotions = PromotionStore.getPromotions();
    // _discountsDisplayPromoCode.forEach((promocode)=>{
    //   if(promocode.value > 0) {
    //     promo = promotions.find((promo) => promo.id === promocode.promotion);
    //     promocode.description = promo.name; // promo.description
    //     promocode.discountId = promo.discountId; // Maitred Discount Id
    //   }
    // });

    // /*
    //   used for Promocode discount text in Cart screen, Components/PromoCode.js
    //   [{
    //     type: 'amount', // string: 'percentage' || 'amount'
    //     value: 0,
    //     reason: String
    //   }]
    // */
    // return _discountsDisplayPromoCode;
  },
  getTssDiscounts(){
    /*
      [{
        type: 'amount', // string: 'percentage' || 'amount'
        value: 0,
        reason: String
      }]
    */
   let discounts = [];
   _data.discounts.forEach((discount) => {
     if(discount.amount > 0) {
      discounts.push({
        id: _data.discounts[0].id,
        type: 'amount',
        value: _data.discounts[0].amount,
        description: _data.discounts[0].reason,
        voucherCode: _data.discounts[0].voucherCode,
        discountId: _data.discounts[0].discountId
      });
     }
   });

    return discounts;
  },
  getDiscountsDisplayVouchers(){
    /*
      used for Vouchers discount text in Cart screen, Components/PromoCode.js
      [{
        type: 'amount', // string: 'percentage' || 'amount'
        value: 0,
        reason: String
      }]
    */
    return _discountsDisplayVouchers;
  },
  getSelectedVouchers(){
    return _data.promotions.vouchers;
  },
  getFulfillmentTime(){
    return _fulfillmentTime;
  },
  getRewardPoints(){
    return _rewardPoints;
  },
  getGiftCardRewardPoints(){
    return _giftCardRewardPoints;
  },
  // getOrderNumber(){
  //   return _orderNumber;
  // },
  getOrder(){
    return _order;
  },
  hasProducts(){
    //Cart can be empty, but still valid because it hasn't expired yet
    return _isAvailable;
  },
  isEmpty(){
    // Cart has no products
    return _data.serverProducts.length === 0;
  },

  emitChangeProductCart(){
    this.emit(CHANGE_PRODUCT_EVENT);
  },
  addChangeProductCart(callback){
    this.on(CHANGE_PRODUCT_EVENT, callback);
  },
  removeChangeProductCart(callback) {
    this.removeListener(CHANGE_PRODUCT_EVENT, callback);
  },

  emitExpireCart(){
    this.emit(EXPIRE_CART);
  },
  addExpireCartListener(callback){
    this.on(EXPIRE_CART, callback);
  },
  removeExpireCartListener(callback) {
    this.removeListener(EXPIRE_CART, callback);
  },


  emitSuccessfulPayment(){
    this.emit(SUCCESSFUL_PAYMENT);
  },
  addSuccessfulPaymentListener(callback){
    this.on(SUCCESSFUL_PAYMENT, callback);
  },
  removeSuccessfulPaymentListener(callback) {
    this.removeListener(SUCCESSFUL_PAYMENT, callback);
  },


  filterProductsAfterChangeType(type){
    type = Services.parseOrderType(type);
    _data.products = _data.products.filter((product)=>product[type] === true);
    this.updateDisplayProducts();
  },

  resetProductServer(){
    _data.serverProducts = [];
    _data.extraCharges = [];
    _resetTotal();
  },

  findProduct(cartID){
    let product = _data.products.filter((product)=>product.cartID === cartID)[0];
    if(product){
      return Services.cloneObject(product);
    }
    return null;
  },

  resetCart(){
    _data.displayProducts = [];
    _data.products = [];
    _data.serverProducts = [];
    _data.promotions = {
      vouchers: [],
      promoCodes: [],
    };
    _data.discounts = [{
      amount: 0,
      reason: '',
      voucherCode: '',
      discountId: 0
    }];
    _resetTotal();
    _isAvailable = false;
    _discounts = [];
    _resetDiscountDisplay();
    _paymentID = null;
    _paymentRemarks = null;
    _payCartID = null;
    _cartID = null;
    _cartId = null;
    _rewardPoints = 0;
    _order = {};

  	this.emitChangeProductCart();
  },
  getPromotionNotIncludesType(type){
     /*
      _ Check paymentTypes of all promotions 
    */
    let selectedPromotions = _discountsDisplayPromoCode.concat(_discountsDisplayVouchers);
    let promotionNotApplicable; //the promotion that does not allow to pay by card type
    if(selectedPromotions.length > 0){
      selectedPromotions.some((selectedPromotion)=>{
        let {position, result} = Services.findObjectInArray(_promotionsAll, {id: selectedPromotion.promotion});

        let isUnavailable = false;
        if(result.paymentTypes){
          isUnavailable = (result.paymentTypes.length > 0 && !result.paymentTypes.includes(type));
        }

        if(isUnavailable === true){
          promotionNotApplicable = result;
        }
        return isUnavailable;
      })
    }
    return promotionNotApplicable;
  },
  getPromotionHasNoCreditCard(){
    return this.getPromotionNotIncludesType('CREDIT_CARD');
  },
  // getPromotionHasNoMasterpass(){
  //   return this.getPromotionNotIncludesType('MASTERPASS');
  // },
  getPromotionIncludesType(type){
    /*
      _ Check paymentTypes of all promotions 
    */
    let selectedPromotions = _discountsDisplayPromoCode.concat(_discountsDisplayVouchers);
    let promotionForPaymentType = []; //the promotion that does not allow to pay by card type
    if(selectedPromotions.length > 0){
      selectedPromotions.some((selectedPromotion)=>{
        let {position, result} = Services.findObjectInArray(_promotionsAll, {id: selectedPromotion.promotion});
        if(result.paymentTypes && result.paymentTypes.includes(type)){
           promotionForPaymentType.push(result);
        }
      })
    }
    return promotionForPaymentType;
  },
  setCartExpired(){
    this.resetCart();
    this.emitExpireCart();
  },
  getCartId(){
    return _cartID;
  },
  getCustomCartId() {
    return _cartId;
  },
  isPromoOrVoucherApplied(){
    return (_data.promotions.promoCodes.length == 0) && (_data.promotions.vouchers.length == 0) && (_data.discounts[0].amount == 0);
  },
  getPromotions(){
    // _discountsDisplayPromoCode.forEach((promocode)=>{
    //   promo = promotions.find((promo) => promo.id === promocode.promotion);
    //   promocode.description = promo.name; // promo.description
    // });

    return _data.promotions;
  },
  getPointPayment(){
    return _souperholicPointPayment();
  },
  setAuthToken(token){
    _authToken = token;
  },
  getAuthToken(){
    return _authToken;
  },

  resetTssDiscount(){
    _data.discounts = [{
      id: 0,
      amount: 0,
      reason: '',
      voucherCode: '',
      discountId: 0
    }];
  },
})	

CartStore.setMaxListeners(0)

CartStore.dispatchData = AppDispatcher.register((action)=>{
	switch(action.type) {
        case Constants.ActionTypes.ADD_PRODUCT_TO_CART:
            CartStore.resetProductServer();
            CartStore.addProductToCart(action.product, action.qty);
            break;
        case Constants.ActionTypes.REMOVE_PRODUCT_FROM_CART:
            CartStore.removeProduct(action.cartID);
            break;
        case Constants.ActionTypes.AJAX_POST_ORDER:
            _postOrder(action.store, _data.promotions, action.errorCallback, action.isRedemption);
            break;
        // case Constants.ActionTypes.SELECT_VOUCHER:
        //     _selectVoucher(action.voucherID);
        //     break;
        // case Constants.ActionTypes.SUBMIT_PROMO_CODE:
        //     _addPromoCode(action.code, action.auto);
        //     break;
        // case Constants.ActionTypes.DELETE_PROMOTION:
        //     _deletePromotion(action.promotionID, action.voucherID);
        //     break;
        case Constants.ActionTypes.UPDATE_CART_AFTER_CHANGE_STORE:
            _removeUnavailableProductsWithStore(action.store, action.callback);
            break;  
        case Constants.ActionTypes.SET_PAYMENT_ID:
            _paymentID = action.paymentID;
            break;
        case Constants.ActionTypes.SET_ORDER_TYPE:
            _setOrderType(action.orderType);
            break;
        case Constants.ActionTypes.RESET_CART:
            CartStore.resetCart();
            break;
        // case Constants.ActionTypes.SET_REWARD_POINTS_IN_CART:
        //     _setRewardPoints(action.points);
        //     break;
        // case Constants.ActionTypes.SET_GIFT_CARD_REWARD_POINTS_IN_CART:
        //     _setGiftCardRewardPoints(action.points);
        //     break;
        // case Constants.ActionTypes.AJAX_GET_ALL_PROMOTIONS:
        //     _getAllPromotions();
        //     break;
        // case Constants.ActionTypes.SET_CART_EXPIRED:
        //     CartStore.setCartExpired();
        //     break;
        case Constants.ActionTypes.SET_PAYMENT_REMARKS:
            _paymentRemarks = action.paymentRemarks;
            break;
        case Constants.ActionTypes.SET_PAYMENT_AMOUNT:
            _paymentAmount = action.amount;
            break;
        case Constants.ActionTypes.SET_PAYMENT_TRANSID:
            _paymentTransId = action.transId;
            break;
        case Constants.ActionTypes.SET_CART_ID:
            _payCartID = action.cartID;
            break;
        case Constants.ActionTypes.SET_CART_CHANGED:
            _isCartChanged = action.cartChanged;
            break;
        case Constants.ActionTypes.SET_CUSTOM_CART_ID:
            _setCartId(action.cartId);
            break;
        // case Constants.ActionTypes.SELECT_TSS_VOUCHER:
        //     _selectTssVoucher(action.voucherID, action.voucherCode);
        //     break;
    }	
})

export default CartStore;

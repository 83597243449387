import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';

const StoreActions = {
	getData(callback) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.AJAX_GET_STORE,
            callback: callback
        })
    },
    select(store, posStoreCode, categoryId, suggestionId, homeSliderId, showSuggestionOn, token, summaryIp, sessionId, pricelist_id, userId) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SELECT_STORE,
            store: store,
            posStoreCode: posStoreCode,
            categoryId: categoryId,
            suggestionId: suggestionId,
            homeSliderId: homeSliderId,
            showSuggestionOn: showSuggestionOn,
            token: token,
            summaryIp: summaryIp,
            sessionId: sessionId,
            pricelistId: pricelist_id,
            userId: userId,
        })
    },
    selectTable(tableID){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SELECT_TABLE,
            tableID: tableID
        })
    },
    selectDatetime(datetime){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SELECT_DATETIME_TAKEAWAY,
            datetime: datetime
        })
    },
    setType(bookingType){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_BOOKING_TYPE,
            bookingType: bookingType
        })
    }
}

export default StoreActions;

import React from 'react';
import {StatusBar, Text, View, Image, TouchableOpacity, Navigator, StyleSheet, ScrollView, Modal, findNodeHandle, UIManager} from 'react-native';

import styleHelper from '../Styles/StyleHelper';

import * as Navigation from '../Routes/Routing';

// import TouchablePot from '../Components/TouchablePot';
import ImageBackground from '../Components/ImageBackground';
import ImageProduct from '../Components/ImageProduct';
import RadioModifierGroup from '../Components/RadioModifierGroup';

import API from '../Utils/API';
import Device from '../Utils/Device';
import Config from '../Utils/Config';
import Services from '../Utils/Services';

import MenuStore from '../Stores/MenuStore';
import CartStore from '../Stores/CartStore';
// import CustomerStore from '../Stores/CustomerStore';
import StoreStore from '../Stores/StoreStore';
import OrderStore from '../Stores/OrderStore';

import OrderActions from '../Actions/OrderActions';
import CartActions from '../Actions/CartActions';
import StoreActions from '../Actions/StoreActions';
import MenuActions from '../Actions/MenuActions';
// import CustomerActions from '../Actions/CustomerActions';

// var RCTUIManager = require('NativeModules').UIManager;
var RCTUIManager = UIManager;

const styles = StyleSheet.create({
  contentBox: {
    // marginTop: Config.isJjk ? Device.getSize(40) : Device.getSize(5),
    // paddingHorizontal: Config.isJjk ? Device.getSize(20) : 0,
    marginTop: Device.getSize(10),
    flex: 2,
  },
  shadow: {
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 3,
      height: 5
    },
    shadowRadius: 5,
    shadowOpacity: 0.5
  },
  menuContainer: {
		minWidth: Device.getSize(160),
		maxWidth: Device.getSize(160),
		minHeight: Device.getSize(225),
		flex: 1,
		marginLeft: Device.getSize(20),
		marginRight: Device.getSize(-20)
	},
  logo: {
		width: Device.getSize(25),
    height: Device.getSize(25),
    position: 'absolute'
  },
  banner: {
    width: Device.width,
    height: Device.getSize(160),
    minHeight: Device.getSize(160),
    maxHeight: Device.getSize(160),
    marginTop: Device.getSize(-10),
    flex: 1
  },
  bannerImg: {
    width: Device.getSize(140),
    height: Device.getSize(170),
    position: 'absolute',
    left: Device.getSize(30),
    top: Device.getSize(0)
    // top: Device.getSize(20)
  },
  blockText: {
    marginTop: Device.getSize(12),
  },
  categoryContainer: {
    maxHeight: Device.getSize(125),
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    marginLeft: Device.getSize(15),
    marginRight: Device.getSize(0),
    marginBottom: Device.getSize(13),
    marginTop: Device.getSize(3),
  },
  orderTypeContainer: {
    minHeight: Device.getSize(35),
    minWidth: Device.getSize(120),
    marginBottom: Device.getSize(10),
  },
  subcategoryContainer: {
    maxHeight: Device.getSize(25),
  },
  categoryName: {
    fontSize: Device.getSize(15),
    textAlign: 'center',
    marginTop: Device.getSize(2),
  },
  borderActive: {
    borderBottomWidth: 3
  },
  shadowActive: {
    backgroundColor: 'white',
    shadowColor: Config.isTss ? '#FF8766' : Config.isJjk ? '#498674' : '#FF8766',
    // shadowColor: '#000',
    shadowOffset: {
      width: 3,
      height: 5
    },
    shadowRadius: 5,
    shadowOpacity: 0.5
  },
  roundedBorder: {
    borderRadius: Device.getSize(10),
    paddingVertical: Device.getSize(7),
    paddingHorizontal: Device.getSize(10),
  },
  borderBottom: {
    position: 'absolute',
    width: '40%',
    height: Device.getSize(1),
    // marginTop: Device.getSize(3),
    left: '30%',
    bottom: -10,
    border: '1px solid #A1412B'
  },
  subcontainer: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: Device.getSize(15),
    marginRight: Device.getSize(0),
    marginBottom: Device.getSize(0),
    marginTop: Device.getSize(0),
    // paddingVertical: Device.getSize(5),
    // paddingHorizontal: Device.getSize(15),
    // margin: Device.getSize(10),
    maxHeight: Device.getSize(85)
  },
  subDescription: {
    width: Device.getSize(300),
    height: Device.getSize(150),
    position: 'absolute',
    right: Device.getSize(25),
    top: Device.getSize(10)
  },
  pickerImage: {
		width: Device.getSize(110),
		height: Device.getSize(35),
  },
  instruction: {
    color: '#FF5100',
    fontSize: Device.getSize(16),
    // fontFamily: 'Recoleta-Bold'
  },
  subpickerContainer: {
    minWidth: Device.getSize(150),
		maxWidth: Device.getSize(150),
		flex: 1,
		marginLeft: Device.getSize(20),
    marginRight: Device.getSize(-10),
    marginBottom: Device.getSize(10)
  },
  subpickerImageContainer: {
    borderRadius: Device.getSize(10),
    paddingTop: Device.getSize(10),
    paddingBottom: Device.getSize(20),
    marginBottom: Device.getSize(3),
    marginTop: Device.getSize(5),
    maxHeight: Device.getSize(125),
  },
  greyishBlueBg:{
    backgroundColor: '#d1d1dd'
  },
  lightOrangeBg:{
    backgroundColor: '#fee1d1'
  },
  subpickerImage: {
		width: Device.getSize(90),
		height: Device.getSize(55),
  },
  breadcum: {
    marginBottom: Device.getSize(5),
    marginTop: Device.getSize(5),
    marginLeft: Device.getSize(25),
    marginRight: Device.getSize(20),
    flex: 1,
    flexDirection: 'row',
    height: Device.getSize(40),
    // width: Device.getSize(300)
  },
  blurBg: {
    width: Device.width,
    height: Device.height,
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
  textRight: {
    textAlign: 'right'
  },
  cartContainer: {
    minHeight: Device.getSize(200),
    maxHeight: Device.getSize(550),
    overflow: 'hidden',
    marginTop: Device.getSize(10),
    marginRight: Device.getSize(20),
    borderRadius: Device.getSize(10),
    shadowColor: '#000',
		shadowOffset: {
		  width: 3,
		  height: 4
		},
		shadowRadius: 5,
    shadowOpacity: 0.5,
  },
  cartHeader: {
    borderTopLeftRadius: Device.getSize(10),
    borderTopRightRadius: Device.getSize(10),
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(5),
  },
  cartSection: {
    position: 'absolute',
    // top: Device.height * Device.getRatio(0.45),
    top: Device.height * Device.getRatio(0.55),
    backgroundColor: '#FFF',
    height: Device.getSize(150),
    width: Device.width,
    paddingTop: Device.getSize(10),
    paddingHorizontal: Device.getSize(10),
  },
  cartButton: {
    paddingVertical: Device.getSize(8),
    paddingHorizontal: Device.getSize(5),
    borderRadius: Device.getSize(5),
  },
  cartQtyBox: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(5),
    width: Device.getSize(30),
    textAlign: 'center',
    backgroundColor: Colors.HeavyOrange,
  },
  spaceHorizontal: {
    paddingHorizontal: Device.getSize(10),
  },
  spaceVerticalSmall: {
    paddingVertical: Device.getSize(5)
  },
  spaceVertical: {
    paddingVertical: Device.getSize(10)
  },
  spaceVerticalBig: {
    paddingVertical: Device.getSize(20)
  },
  btnCheckout: {
    borderRadius: Device.getSize(10),
  },
  customBtn: {
	  borderRadius: Device.getSize(10),
	  minWidth: Device.getSize(100),
	  maxHeight: Device.getSize(40),
	  marginTop: Device.getSize(10),
	  marginHorizontal: Device.getSize(5),
	  paddingHorizontal: Device.getSize(20)
	},
  button: {
    paddingVertical: Device.getSize(5),
    paddingHorizontal: Device.getSize(10),
    // paddingHorizontal: Device.getSize(5),
    borderRadius: Device.getSize(5),
    marginRight: Device.getSize(5),
    // marginBottom: Device.getSize(10)
    marginBottom: Device.getSize(5)
  },
});

const productStyles = StyleSheet.create({
  menuContainer: {
		minHeight: Device.getSize(120),
		// maxHeight: Device.getSize(125),
	},
	container: {
		borderRadius: Device.getSize(5),
		backgroundColor: '#FFF',
    marginHorizontal: Device.getSize(20),
    paddingHorizontal: Device.getSize(5),
    paddingTop: Device.getSize(10),
    paddingBottom: Device.getSize(10),
		marginBottom: Device.getSize(2),
  },
  popupContainer: {
    width: Device.width * 0.9,
    height: Device.height ? Device.height <= 926 ? Device.height * 0.9 : Device.getSize(500) : Device.getSize(500),
    backgroundColor: '#FFF',
    paddingVertical: Device.getSize(10),
    // paddingHorizontal: Device.getSize(10),
    marginTop: Device.getSize(20),
    marginBottom: Device.getSize(20),
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: Device.getSize(10)
  },
  popupContainer2: {
    width: Device.width * 0.7,
    height: Device.getSize(150),
    backgroundColor: '#FFF',
    paddingVertical: Device.getSize(10),
    // paddingHorizontal: Device.getSize(10),
    marginTop: Device.height * 0.4,
    marginBottom: Device.getSize(20),
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: Device.getSize(10)
  },
  customBtn: {
	  borderRadius: Device.getSize(10),
	  width: Device.width * 0.8,
	  maxHeight: Device.getSize(30),
	  marginTop: Device.getSize(10)
  },
  qtyInput: {
    width: Device.getSize(100)
  }
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.state = {
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
  }

  onCartChange() {
    setTimeout(() => {
      MenuActions.updateMenuSection();
    },500);
  }

	render() {
    let multipleCategories = MenuStore.getMultipleCategories(StoreStore.getCategoryId());
		return(
      <ImageBackground>
          <View style={[]}>
            <MenuDetails
              categoryID={multipleCategories[0].id.toString()}
              categoryName={multipleCategories[0].name} />
          </View>
      </ImageBackground>
		)
	}
}

class MenuDetails extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.onVariantChange = this.onVariantChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleModifierScroll = this.handleModifierScroll.bind(this);
    this.sortProducts = this.sortProducts.bind(this);
    this.navigateWelcome = this.navigateWelcome.bind(this);
    this.navigateToProduct = this.navigateToProduct.bind(this);
    this.setActiveCategory = this.setActiveCategory.bind(this);
    this.refreshView = this.refreshView.bind(this);
    this.applyTssCustomModifier = this.applyTssCustomModifier.bind(this);
    this.renderTopNav = this.renderTopNav.bind(this);
    this.renderCategorySlider = this.renderCategorySlider.bind(this);
    this.renderSubCategory = this.renderSubCategory.bind(this);
    this.renderProduct = this.renderProduct.bind(this);
    this.renderPopupMsg = this.renderPopupMsg.bind(this);
    this.renderProducts = this.renderProducts.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.renderModifiers = this.renderModifiers.bind(this);
    this.modifier = this.modifier.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
    this.renderAdd = this.renderAdd.bind(this);
    this.updateModifierValid = this.updateModifierValid.bind(this);
    this.toggleCustomModifiers = this.toggleCustomModifiers.bind(this);
    this.updateModifier = this.updateModifier.bind(this);
    this.reselectModifier = this.reselectModifier.bind(this);
    this.deselectModifier = this.deselectModifier.bind(this);
    this.resetModifierGroup = this.resetModifierGroup.bind(this);
    this.addProduct = this.addProduct.bind(this);
    this.renderProductQty = this.renderProductQty.bind(this);
    this.hideProduct = this.hideProduct.bind(this);
    this.hideSuggestion = this.hideSuggestion.bind(this);
    this.hidePopupMsg = this.hidePopupMsg.bind(this);
    this.renderSkip = this.renderSkip.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.state = {
      categories: MenuStore.getMultipleCategories(StoreStore.getCategoryId()),
      sections: MenuStore.getMenuDetailsSections(),
      // imagesUri: [],
      activeCategory: this.props.categoryID,
      activeIndex: 0,
			// promoCodes: CartStore.getDiscountsDisplayPromoCodes(),
			// vouchers2: CartStore.getDiscountsDisplayVouchers(),
			// _promoCode: null,
      nodes: new Map(),
      modifierNodes: new Map(),
      x: 0,
      y: 0,
      positions: [],
      catPositions: [],
      subPositions: [],
      // showDiscount: !CartStore.isEmpty(),
      // showPayment: this.props.showGiftCardPayment == "true" ? true : false,
      // showGiftCardSlider: this.props.showGiftCardPayment == "true" ? true : false,
      grandTotal: CartStore.getGrandTotal(),
      showProduct: false,
      productSubtotal: OrderStore.getCurrentSubtotal(),
      // showPromo: false,
      // showVoucher: false,
      // vouchers: CustomerStore.getTssVouchers(),
      showDetail: false,
      detailKey: 0,
      // showDescription: true,
      // showTnC: false,
      showSuggestion: false,
      showSuggestionAgain: false,
      products: CartStore.getServerProducts(),
      productQty: 1,
      isOne: false,
      modifierGroups: [],
      modifierGroupsValid: [],
      modifierY: 0,
      cartQuantity: CartStore.getQuantity(),
      imgHeight: 0,
      imgWidth: 0,
      showPopupMsg: false,
      msg: '',
    };
  }

  componentDidMount() {
    // Services.showSpinner();
    CartStore.addChangeProductCart(this.onCartChange);
		OrderStore.addChangeVariantListener(this.onVariantChange);

    let pos, pos2, pos3;
    let categories = this.state.categories;
    let count = 0;
    Services.onFocusCallback(()=>{
      pos = this.state.positions;
      pos2 = this.state.subPositions;
      pos3 = this.state.catPositions;

      try {
        categories.forEach((category, i)=>{
          this.state.sections[category.id].subcategories.forEach((subcategory,j)=>{
            if(subcategory.products) {
              let sortedProducts = this.sortProducts(subcategory.products);
              if(sortedProducts.length > 1) {
                count += 1;
              }
  
              const node = this.state.nodes.get(subcategory.id);
              const position = findNodeHandle(node);
              RCTUIManager.measure(position, (fx, fy, w, h, px, py)=>{
                pos.push({
                  id: subcategory.id,
                  y: py
                });
              });
  
              const node2 = this.state.nodes.get('00' + subcategory.id);
              const position2 = findNodeHandle(node2);
              RCTUIManager.measure(position2, (fx, fy, w, h, px, py)=>{
                pos2.push({
                  id: '00' + subcategory.id,
                  x: px
                });
              });
            }
          });
  
          if(count > 0) {
            count = 0;
            const node3 = this.state.nodes.get('00' + category.id);
            const position3 = findNodeHandle(node3);
            RCTUIManager.measure(position3, (fx, fy, w, h, px, py)=>{
              pos3.push({
                id: '00' + category.id,
                x: px
              });
            });
          }
        });
      } catch(BreakException) {
      }
      
      this.setState({
        positions: pos,
        subPositions: pos2,
        catPositions: pos3,
      });

      // Services.hideSpinner();
    });
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
		OrderStore.removeChangeVariantListener(this.onVariantChange);
  }

  onCartChange() {
    setTimeout(() => { // make warning
      this.setState({
        sections: MenuStore.getMenuDetailsSections(),
        grandTotal: CartStore.getGrandTotal(),
        products: CartStore.getServerProducts(),
        // showDiscount: !CartStore.isEmpty(),
        // promoCodes: CartStore.getDiscountsDisplayPromoCodes(),
        // vouchers2: CartStore.getDiscountsDisplayVouchers(),
        cartQuantity: CartStore.getQuantity(),
      })
    }, 700);
  }

  onVariantChange(){
    let modifierGroups = this.applyTssCustomModifier();
		this.setState({
      productSubtotal: OrderStore.getCurrentSubtotal(),
			modifierGroups: modifierGroups
		});
	}

  handleScroll(event){
    this.setState({
      y: event.nativeEvent.contentOffset.y
    });

    // sort from highest y
    this.state.positions.sort(function(a,b){
      return b.y-a.y;
    });

    try {
      let len = 0;
      this.state.positions.forEach((position,i)=>{
        if(event.nativeEvent.contentOffset.y + 360 >= position.y) {          
          let index = this.state.positions.length - (i + 1);
          // change active subcategory
          this.setState({
            activeIndex: index
          });
          this.subScroll.scrollTo({x: this.state.subPositions[index].x - 10, y: 0, animated: true});

          //change active brand
          this.state.categories.forEach((category,i)=>{
            len += this.state.sections[category.id].subcategories.length;
            if(len > index) {
              this.setState({
                activeCategory: category.id
              });
              this.catScroll.scrollTo({x: this.state.catPositions[i].x - 20, y: 0, animated: true});    

              // break iteration
              throw BreakException;
            }
          });

          // break iteration
          throw BreakException;
        } else {
          this.setState({
            activeIndex: 0
          });
        }
      });
    } catch(e) {

    }
  }

  handleModifierScroll(event) {
    this.setState({
      modifierY: event.nativeEvent.contentOffset.y
    });
  }

  sortProducts(products){
    let sortedProducts = [];
    let productChunk = [];
    let orderType = Services.parseOrderType(OrderStore.getType());

    products.map((product, i)=>{
      if(product[orderType] == true) {
        if(i % 1 === 0){
          sortedProducts.push(productChunk);
          productChunk = [];
          productChunk.push(product);
        } else {
          productChunk.push(product);
        }
      }
    });
    sortedProducts.push(productChunk);
    return sortedProducts;
  }

  // apply TSS custom modifier logic
  applyTssCustomModifier() {
    let modifierMaps = MenuStore.getModifierMap();
    let modifierGroups = OrderStore.getModifierGroups();
    let newModifierGroups = [];
    modifierGroups.forEach((modifierGroup)=>{
      modifierGroup.hide = false;
      modifierGroup.modifier1 = 0;

      // DB was designed for multi modifiers, but temporarily treat as single modifier
      // apply_to_show was currently unused bcos leak of time to do (urgently needed)
      let qty = 0;
      modifierMaps.forEach(modifierMap => {
        if(modifierGroup.id == modifierMap.modifier2 && modifierMap.apply_to_show) {
          qty = 0;
          // check if parent selected
          modifierGroups.forEach((mg)=>{
            if(mg.id == modifierMap.modifier1) {
              mg.product_modifier_ids.forEach(m => {
                qty += m.quantity;
              });
            }
          });

          if(qty < 1) {
            modifierGroup.hide = true; // hide if parent not selected
          } else {
            modifierGroup.hide = false; // show if parent selected
          }
          modifierGroup.modifier1 = modifierMap.modifier1;
        }
      });
      newModifierGroups.push(modifierGroup);
    });

    return newModifierGroups;
  }

  hideProduct() {
    this.setState({
      showProduct: false,
      modifierGroupsValid: [true, true, true, true, true, true],
      productQty: 1,
      isOne: false
    });

    if(this.state.showSuggestionAgain) {
      this.setState({
        showSuggestion: true,
        showSuggestionAgain: false,
      });  
    }
  }

  hideSuggestion() {
    this.setState({
      showSuggestion: false,
    });
  }

  hidePopupMsg() {
    this.setState({
      showPopupMsg: false,
    });
  }

  navigateWelcome() {
    Navigation.navigate('Welcome');
  }

  navigateToProduct(product) {
    OrderStore.setSelectedProduct(product);

    let modifierValids = [];
    OrderStore.getModifierGroups().forEach((modifier, i)=>{
      modifierValids.push(true);
    });

    let modifierGroups = this.applyTssCustomModifier();
    
    this.setState({
      showProduct: true,
      productSubtotal: OrderStore.getCurrentSubtotal(),
      modifierGroups: modifierGroups,
      modifierGroupsValid: modifierValids,
      modifierY: 0,
    });

    if(this.state.showSuggestion) {
      this.setState({
        showSuggestion: false,
        showSuggestionAgain: true,
      });  
    }
  }

  setActiveCategory(index, name){
    // Scroll to category
    let offset = Device.getSize(-200);
    try {
      const node = this.state.nodes.get(index);
      const position = findNodeHandle(node);
      RCTUIManager.measure(position, (fx, fy, w, h, px, py)=>{
        this.setState({
          y: this.state.y + py + offset
        });
        this.myScroll.scrollTo({x: 0, y: this.state.y + py + offset, animated: true});
      });
    } catch(BreakException) {

    }
  }

  refreshView(index, id){
    // Scroll to subcategory
    let offset = Device.getSize(-200);
    try {
      const node = this.state.nodes.get(id);
      const position = findNodeHandle(node);
      RCTUIManager.measure(position, (fx, fy, w, h, px, py)=>{
        this.setState({
          y: this.state.y + py + offset
        });
        this.myScroll.scrollTo({x: 0, y: this.state.y + py + offset, animated: true});
      });
    } catch(BreakException) {
      
    }
  }

  renderCategorySlider(){
    let categories = this.state.categories;
    let count = 0;

    return(
        <ScrollView horizontal={true} style={[styleHelper.borderBottomWhiteTransparent]}
          ref={(ref)=>{
            this.catScroll = ref
          }}>
          {
            categories.map((category, i)=>{
              this.state.sections[category.id].subcategories.map((section, i)=>{
                if(section.products) {
                  let sortedProducts = this.sortProducts(section.products);
                  if(sortedProducts.length > 1) {
                    count += 1;
                  }
                }
              });

              if(count > 0) {
                count = 0;
                return(
                  <TouchableOpacity
                    key={category.id}
                    style={[styles.roundedBorder, styles.categoryContainer, styles.container, category.id==this.state.activeCategory && styles.shadowActive, category.id==this.state.activeCategory && styleHelper.OrangeDarkBorder]}
                    onPress={ ()=> { this.setActiveCategory(category.id, category.name); } }
                    ref={(ref) => {
                      this.state.nodes.set('00' + category.id, ref);
                    }}>

                    <Image
                      source={{
                        uri: Services.getImageURL(category.id, 'category'),
                        cache: 'force-cache'
                      }}
                      style={styles.pickerImage}
                      resizeMode={'contain'} />
                    
                  </TouchableOpacity>
                )
              } else {
                return (
                  <View key={category.id}
                    ref={(ref) => {
                      this.state.nodes.set('00' + category.id, ref);
                    }} />
                )
              }
            })
          }
        </ScrollView>
    )
  }

	renderSubCategory(sections){
    let len = 0;
    let index = 0;
    let categoryId;
    let categories = this.state.categories;
		return(
      <ScrollView horizontal={true} style={[styleHelper.borderBottomWhiteTransparent, {paddingTop: Device.getSize(10)}]}
        ref={(ref)=>{
          this.subScroll = ref
        }}>
          <View style={[{flexDirection: 'row'}]}>
          {
            categories.map((category, j)=>{
              if(j > 0) {
                len += sections[categoryId].subcategories.length;
              }
              categoryId = category.id;
              return (
                <View key={j} style={[{flexDirection: 'row'}]}>
                {
                  sections[category.id].subcategories.map((section, i)=>{
                    if(section.products) {
                      let sortedProducts = this.sortProducts(section.products);
                      if(sortedProducts.length > 1) {
                        index = i;
                        if(j > 0) {
                          index = len+i;
                        }

                        return(
                          <TouchableOpacity
                            onPress={ ()=> { this.refreshView(i, section.id) } }
                            key={i}
                            style={[styles.subcategoryContainer, styles.container]}
                            ref={(ref) => {
                              this.state.nodes.set('00' + section.id, ref);
                            }}>
                              <Text style={[styles.categoryName, styleHelper.OrangeDarkFont, styleHelper.fontHelveticaNeueBold, index==this.state.activeIndex && styleHelper.DarkRedFont]}>
                                {section.name}
                              </Text>

                              <View style={[index==this.state.activeIndex && styles.borderBottom]} />
                          </TouchableOpacity>
                        )
                      } else {
                        return (
                          <View key={i}
                            ref={(ref) => {
                              this.state.nodes.set('00' + section.id, ref);
                            }} />
                        )
                      }
                    }
                  })
                }
                </View>
              )
            })
          }
          </View>
      </ScrollView>
		)
  }

  // PRODUCT
  // TO BE CHANGED
  addProduct() {
    let is_oos = true;
    let is_timeout = true;
    const product = OrderStore.getSelectedProduct();

    // oos err msg
    if(product.available_for_order) {
      product.available_for_order = false;
      product.err_msg = 'We’re sorry, this item is unavailable right now!';
    }

    API.getCategoryById(product.category_ids[0].id, {
      success: re=>{
        API.getProduct(product.id, {
          success: (res)=>{
            // check oos
            let storeId = StoreStore.getSelectedStore().id;
            res.oos_shop_ids.forEach(shop_id => {
              if(shop_id.id == storeId) {
                is_oos = false;
              }
            });
    
            // category available time
            if(re.pos_category_day_ids.length > 0) {
              let today = new Date();
              let dayname = today.toLocaleDateString('en-us', { weekday: 'long' });
              let hour = today.getHours();
              let min = today.getMinutes();
              let time = hour + (min / 60);
              re.pos_category_day_ids.forEach(activeTime => {
                if(activeTime.name == dayname.toLowerCase()) {
                  if(activeTime.start_time <= time && activeTime.end_time > time) {
                    is_timeout = false;
                  }
                }
              });
            } else {
              is_timeout = false;
            }
    
            if(!is_oos && !is_timeout) {
              product.available_for_order = true;
              product.err_msg = '';
            }
    
            if(product.available_for_order) {
              const category = MenuStore.getCategory(StoreStore.getSuggestionId());
              const showSuggestionOns = StoreStore.getShowSuggestionOn();
          
              if(OrderStore.isSelectedModifierValid() === true) {
                CartActions.addProductToCart(product, this.state.productQty);
                CartActions.postOrder(OrderStore.getData());
          
                // // recalculate discount
                // let tssDiscount = CartStore.getTssDiscounts();
                // if(tssDiscount.length > 0){
                //   if(tssDiscount[0].id > 0 && tssDiscount[0].value > 0) {
                //     // Wait till products updated
                //     setTimeout(() => {
                //       CartActions.selectTssVoucher(tssDiscount[0].id);
                //     }, 500);
                //   }
                // }
          
                this.setState({
                  showProduct: false
                });
          
                if (!product.cartID) {
                  let temp = showSuggestionOns.split(',');
                  temp.forEach((showSuggestionOn, index)=>{
                    if(showSuggestionOn == product.category_ids[0].id && category) {
                      this.setState({
                        showSuggestion: true
                      });
                      return;
                    }
                  })
                }
          
                // reset qty
                this.setState({
                  productQty: 1,
                  isOne: false
                });
              } else {
                // Services.showAlertError('Please select some modifiers in each group');
                this.setState({
                  modifierGroupsValid: OrderStore.checkModifierGroupsValid(),
                });
          
                try {
                  OrderStore.checkModifierGroupsValid().forEach((modifierValid, i)=>{
                    if(!modifierValid) {
                      const modifierNode = this.state.modifierNodes.get(i);
                      const position = findNodeHandle(modifierNode);
                      RCTUIManager.measure(position, (fx, fy, w, h, px, py)=>{
                        this.setState({
                          modifierY: this.state.modifierY + py - 300
                        });
                        this.modifierScroll.scrollTo({x: 0, y: this.state.modifierY + py - 300, animated: true});
                      });
                      // throw BreakException;
                    }
                  });
                } catch(e) {
                  // do nothing
                }
              }
            } else {
              if(product.err_msg) {
                this.setState({
                  msg: product.err_msg,
                  showPopupMsg: true,
                  showProduct: false
                });
              } else {
                this.setState({
                  msg: 'We’re sorry, this item is unavailable right now!',
                  showPopupMsg: true,
                  showProduct: false
                });
              }
            }
          },
          error: (err)=>{
            Services.showAlertError(err);
          }
        });
      },
      error: e=>{
        Services.showAlertError(e);
      }
    })
  }
    
  renderProductQty(qty) {
    return(
        <View style={[styleHelper.flexRowSpaceBetween, productStyles.qtyInput]}>
          <IconMinus onPress={()=> {
            if(qty > 1 && this.state.productQty <= 1 && !this.state.isOne) {
              this.setState({
                productQty: qty-1
              })

              // trick's key
              if(this.state.productQty == 1) {
                this.setState({
                  isOne: true
                })
              }
            } else {
              if(this.state.productQty > 1) {
                this.setState({
                  productQty: this.state.productQty-1
                })
              }
            }
          }} />
          <View
            style={[
              styleHelper.inputBorderRadius,
              styleHelper.bgWhite,
              styleHelper.containerNumber2,
            ]}>
            <Text
              style={[
                styleHelper.fontRegular,
                styleHelper.fontBlack,
                styleHelper.font16,
                styleHelper.textCenter,
              ]}>
              {this.state.productQty > 1 ? this.state.productQty : this.state.isOne ? 1 : qty > 0 ? qty : 1}
            </Text>
          </View>
          <IconPlus onPress={()=> {
            if(qty > 0 && this.state.productQty <= 1 && !this.state.isOne) {
              this.setState({
                productQty: qty+1
              })
            } else {
              this.setState({
                productQty: this.state.productQty+1
              })
            }
          }} />
        </View>
    )
  }

  // MODIFIERS
  updateModifierValid(index) {
    let modifierGroupsValid = this.state.modifierGroupsValid;
    modifierGroupsValid[index] = OrderStore.checkModifierGroupsValid()[index];
    this.setState({
      modifierGroupsValid: modifierGroupsValid,
    });
  }

  toggleCustomModifiers(modifierGroup, hide) {
    let newMG = [];
    let parentModifier = 0;
    this.state.modifierGroups.forEach((mg)=>{
      // recursive call for children
      if(parentModifier != 0) {
        this.state.modifierGroups.forEach((mg2)=>{
          if(parentModifier == mg2.modifier1) {
            parentModifier = 0;
            this.toggleCustomModifiers({id: mg2.id}, hide);
          }
        });
      }

      if(mg.id == modifierGroup.id) {
        if(hide) {
          parentModifier = mg.id;
          this.resetModifierGroup(mg);
        } else {
          mg.hide = hide;
        }
      }

      newMG.push(mg);
    });

    this.setState({
      modifierGroups: newMG
    });
  }

  updateModifier(modifier, modifierGroup, index) {
    OrderActions.selectModifier(modifier);
    this.updateModifierValid(index);

    // show certain modifierGroup
    this.toggleCustomModifiers(modifierGroup, false);
  }

  reselectModifier(modifier, modifierGroup, index) {
    OrderActions.selectModifierV2(modifier, modifierGroup);
    this.updateModifierValid(index);
  }

  deselectModifier(modifier, modifierGroup, index) {
    OrderActions.deselectModifier(modifier);
    this.updateModifierValid(index);

    // hide certain modifierGroup
    this.toggleCustomModifiers(modifierGroup, true);
  }

  resetModifierGroup(modifierGroup) {
    OrderActions.resetModifierGroup(modifierGroup);
  }

  renderAdd(modifier, modifierGroup, index) {
    /**
     * Selected
     * Max selection is 1
     */
		if(modifier.max_qty == 1 && modifier.quantity > 0){
			if(modifier.preSelected){
				return(
          <TouchableRadio
            disabled={true}
            selected={true} />
			  	)
			} else {
				return (
        <TouchableRadio
          selected={true}
          modifier={modifier}
          modifierGroup={modifierGroup}
          index={index}
          onPress={this.deselectModifier} />
        )
      }
		} else {
      /**
       * Not Selected, OR Selected but max qty not reached
       * Max qty of modifier group not reached
       */
			if(modifierGroup.numberSelectedModifier < modifierGroup.max_modifier || modifier.quantity === modifier.max_qty){
				if(modifier.max_qty > 1){
					//Render quantity box
					return(
						<BoxQuantity 
							onPressMinus={()=>{
								OrderActions.deselectModifier(modifier);
                this.updateModifierValid(index);
							}}
							onPressPlus={()=>{
								OrderActions.selectModifier(modifier);
                this.updateModifierValid(index);
							}}
							modifier={modifier}/>
					)
				} else {
					return (
            <TouchableRadio
              selected={false}
              modifier={modifier}
              modifierGroup={modifierGroup}
              index={index}
              onPress={this.updateModifier} />
					)
				}
      /**
       * Selected but max qty not reached
       * Max qty or modifier group reached
       * else chunk below almost imposible to trigger
       */
			} else { // not selected, 
				if(modifier.max_qty > 1){
					//Render quantity box
					return(
						<BoxQuantity 
							onPressMinus={()=>{
								OrderActions.deselectModifier(modifier)
                this.updateModifierValid(index);
							}}
							disabled={true}
							modifier={modifier}/>
					)
				} else { // almost imposible to trigger
					return (
            <TouchableRadio
              selected={false}
              modifier={modifier}
              modifierGroup={modifierGroup}
              index={index}
              onPress={this.reselectModifier} />
					)
				}
			}
		}
	}

	renderPrice(price) {
		if(price > 0){
			return(
				<Text style={[styleHelper.fontGrey, styleHelper.fontHelveticaNeueRegular, styleHelper.font10, styleHelper.containerHorizontalSmall]}>
					(+{Services.formatPrice(price)})
				</Text>
			)
		}
  }

  modifier(modifierGroup, index) {
    let storeId = StoreStore.getSelectedStore().id;
    let oos = false;
    return(
      <View>
        {
          modifierGroup.product_modifier_ids.map((modifier, i)=>{
            oos = false;
            modifier.oos_store.forEach(shop_id => {
              if(shop_id.id == storeId) {
                oos = true;
              }
            });

            if(!oos) {
              return (
                <View key={i} style={[styleHelper.flexRowSpaceBetweenAlignTop, styleHelper.containerVerticalSmall2]}>
                  <View style={styleHelper.flex1}>
                    <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font12, styleHelper.DarkGreenFont, modifier.quantity > 0 && styleHelper.DarkFont]}>
                      {modifier.product_id.name}
                    </Text>
                  </View>
      
                  <View style={styleHelper.flexRowSpaceBetween}>
                    {this.renderPrice(modifier.price)}
                    {this.renderAdd(modifier, modifierGroup, index)}
                  </View>
                </View>
              )
            } else {
              return <View key={i} />
            }
          })
        }
      </View>
    )
  }

  renderModifiers() {
		if(this.state.modifierGroups.length ===  0){
			return null;
		}

		return(
			<View>
				{
					this.state.modifierGroups.map((modifierGroup, i)=>(
            !modifierGroup.hide ?
            (
              <View style={styleHelper.containerVerticalNormal} key={i}>
                <View style={[styleHelper.flexRow]}>
                  {
                    !this.state.modifierGroupsValid[i] &&
                    <View style={[styleHelper.flexCenter, {borderRadius: Device.getSize(20), backgroundColor: '#F00', width: Device.getSize(15), height: Device.getSize(15), marginRight: Device.getSize(5), padding: Device.getSize(5)}]}>
                      <Text style={[styleHelper.fontWhite, styleHelper.font12, styleHelper.fontHelveticaNeueRegular]}>
                        !
                      </Text>
                    </View>
                  }

                  <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font12, styleHelper.containerVerticalTiny, styleHelper.DarkGreenFont, !this.state.modifierGroupsValid[i] && styleHelper.fontRed]}
                    ref={(ref) => {
                      this.state.modifierNodes.set(i, ref);
                    }}>
                    {modifierGroup.name}
                  </Text>
                </View>

                <View style={[styleHelper.borderBottomWhiteTransparent, styleHelper.flexRow]}>
                  <Text style={[styleHelper.fontHelveticaNeue, styleHelper.font11, styleHelper.fontBlackSecond, styleHelper.containerVerticalTiny]}>
                    {modifierGroup.description}
                  </Text>
                </View>

                {this.modifier(modifierGroup, i)}
              </View>
            ) : <View key={i} />
					))
				}
			</View>		
		)
  }

  // Menu Product Popup
  renderProduct() {
    let product = OrderStore.getSelectedProduct();
    // let images = [];
    let cart = require('../img/ic_cart_white.png');
    if(!product) {
      return <View />;
    }
    // if(product.id){
    //   images[product.id] = this.cacheImage(product.id, product.image_id);
    // }

    Image.getSize(Services.getImageURL(product.id, 'product'), (imageWidth, imageHeight) => {
      let scaledImage = Services.getImageScaledSize(imageWidth, imageHeight, Device.width * 0.9);
      this.setState({
        imgWidth: scaledImage.width,
        imgHeight: scaledImage.height
      })
    });

    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.showProduct}>
        
        <View style={[styleHelper.flex1, styles.blurBg, {alignItems: 'center'}]}>
          <View style={[productStyles.popupContainer]}>

            <View style={[styleHelper.borderBottomBlackTransparent, styleHelper.flexCenter, {paddingVertical: Device.getSize(10)}]}>
              <Text style={[styleHelper.font16, styleHelper.fontHelveticaNeueBold, styleHelper.OrangeDarkFont]}>
                Product
              </Text>
              
              <TouchableOpacity
                onPress={()=>this.hideProduct()}
                style={[{position: 'absolute', top: Device.getSize(5), right: Device.getSize(5)}]}>
                  <Image
                    source={require('../Img/ic_close.png')}
                    resizeMode={'contain'}
                    style={[{width: Device.getSize(20), height: Device.getSize(20)}]} />
                {/* <Text style={[styleHelper.font25, styleHelper.fontHelveticaNeueBold, styleHelper.OrangeDarkFont]}>X</Text> */}
              </TouchableOpacity>
            </View>

            {/* <ScrollView style={[{borderRightColor: '#CCC', borderRightWidth: 2, paddingHorizontal: Device.getSize(5)}]} */}
            <ScrollView
              ref={(ref)=>{
                this.modifierScroll = ref
              }}
              scrollEventThrottle={0}
              onScroll={this.handleModifierScroll}>
                {/* <View style={[styleHelper.flexRow]}> */}
                  <Image
                    source={{
                        uri: Services.getImageURL(product.id, 'product')
                    }}
                    resizeMode={'contain'}
                    style={[{width: this.state.imgWidth, height: this.state.imgHeight}]} />
                {/* </View> */}

                <View style={[{paddingHorizontal: Device.getSize(10)}]}>
                  <View style={[styleHelper.flexRowSpaceBetween, {marginBottom: Device.getSize(10)}]}>
                    <Text
                      style={[
                        styleHelper.fontHelveticaNeueBold,
                        styleHelper.fontBlack,
                        styleHelper.font14,
                        styleHelper.containerVerticalNormal,
                        {flex: 2}
                      ]}>
                      {product.name}
                    </Text>

                    <View style={[{flex: 1}]}>
                      <Text
                        style={[
                          styleHelper.fontHelveticaNeueBold,
                          styleHelper.fontBlack,
                          styleHelper.font14,
                          styleHelper.containerVertical2,
                          {textAlign: 'right'}
                        ]}>
                        {Services.formatPrice(this.state.productSubtotal)}
                      </Text>

                      <Text
                        style={[
                          styleHelper.fontHelveticaNeueRegular,
                          styleHelper.fontXDark,
                          styleHelper.font12,
                          {textAlign: 'right'}
                        ]}>
                        Base price
                      </Text>
                    </View>
                  </View>

                  <ProductDescription description={product.description_sale}/>

                  <View style={[{paddingTop: Device.getSize(15)}]}>
                    {product.product_variant_ids.length > 1 &&
                      <RadioModifierGroup data={product.product_variant_ids} />
                    }
                    {this.renderModifiers()}
                  </View>
                </View>
            </ScrollView>

            <View style={[styleHelper.flexCenter, {paddingHorizontal: Device.getSize(5)}]}>
                {this.renderProductQty(product.quantity)}

                <TouchableOpacity
                  onPress={()=>{this.addProduct()}}
                  activeOpacity={Config.activeOpacity} 
                  style={[product.available_for_order && styleHelper.OrangeDarkBg, !product.available_for_order && styleHelper.bgLightGrey, productStyles.customBtn, styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter]}>

                  <Image 
                      source={cart}
                      style={styleHelper.verysmallIconSize}
                      resizeMode={'contain'} />

                  <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.fontWhiteGold, styleHelper.font14, styleHelper.textCenter]}>
                    {!product.cartID ? 'ADD TO CART' : 'UPDATE ITEM'} - {Services.formatPrice(this.state.productSubtotal * this.state.productQty)}
                  </Text>

                </TouchableOpacity>
            </View>

          </View>
        </View>

      </Modal>
    )
  }

  renderSkip(){
    return(
        <View style={[styleHelper.flexCenter]}>
            <TouchableOpacity
                activeOpacity={Config.activeOpacity}
                onPress={()=>{
                  this.hideSuggestion();
                }}
                style={[styleHelper.containerTouchableBig, styleHelper.flexRow, styleHelper.flexCenter, styleHelper.OrangeDarkBg, styles.customBtn]}
                >
                <Image
                    source= {require('../img/Skip2.png')}
                    resizeMode={'contain'}
                    style={[{width: Device.getSize(55)}]}
                    />
            </TouchableOpacity>
        </View>
    );
  }

  renderSuggestion() {
    let section = MenuStore.getMenuDetailsSections()[StoreStore.getSuggestionId()];
    if(section) {
      if(section.productNoSubCategory){
        if(section.productNoSubCategory.length > 0){
            let sortedProducts = this.sortProducts(section.productNoSubCategory);
            return(
              <Modal
                animationType="fade"
                transparent={true}
                visible={this.state.showSuggestion}>
              
                <View style={[styleHelper.flex1, styles.blurBg, {alignItems: 'center'}]}>
                  <View style={[productStyles.popupContainer]}>
                    <View style={[styleHelper.borderBottomBlackTransparent, styleHelper.flexCenter, {paddingVertical: Device.getSize(10)}]}>
                      <Text style={[styleHelper.font14]}>
                        Upgrade Soup?
                      </Text>
                      
                      <TouchableOpacity
                        onPress={()=>this.hideSuggestion()}
                        style={[{position: 'absolute', top: Device.getSize(5), right: Device.getSize(5)}]}>
                        <Text style={[styleHelper.font25, styleHelper.fontHelveticaNeueBold, styleHelper.OrangeDarkFont]}>X</Text>
                      </TouchableOpacity>
                    </View>
        
                      <ScrollView>
                        {this.renderProducts(sortedProducts)}
                      </ScrollView>
    
                      {this.renderSkip()}
                  </View>
                </View>
              </Modal>
            )
        }
      }
    }
  }
  
  renderPopupMsg() {
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.state.showPopupMsg}>
        
        <View style={[styleHelper.flex1, styles.blurBg, {alignItems: 'center'}]}>
          <View style={[productStyles.popupContainer2]}>
            <View style={[styleHelper.flexRow, styleHelper.borderBottomBlackTransparent, styleHelper.flexCenter,
                {height: Device.getSize(100), marginBottom: Device.getSize(10), paddingHorizontal: Device.getSize(10)}]}>
              <Text style={[styleHelper.fontRegular, styleHelper.font14, styleHelper.fontBlack, styleHelper.textCenter]}>
                {this.state.msg}
              </Text>
            </View>

            <TouchableOpacity
              onPress={()=>this.hidePopupMsg()}
              style={[styleHelper.flexCenter]}>
                <Text style={[styleHelper.font14, styleHelper.fontRegular, styleHelper.fontOrange]}>
                  Ok
                </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>  
    )
  }

  // Menu Products List
  renderProducts(products) {
    return(
      <View>
      {
        products.map((sortedProduct, j)=>(
          sortedProduct.length > 0 && 
            <View key={j}>
              {
                sortedProduct.map((product, i)=>(
                  <View style={[productStyles.container]} key={i}>
                    <TouchableOpacity
                      activeOpacity={Config.activeOpacity}
                      onPress={()=>this.navigateToProduct(product)}
                      style={[productStyles.menuContainer]}>

                      <View style={[styleHelper.flex1, styleHelper.flexRowSpaceBetween]}>
                        <View style={[styleHelper.flex3, {marginRight: Device.getSize(5)}]}>
                          <View style={[styleHelper.containerText, {marginLeft: Device.getSize(2)}]}>
                            <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font15]}>
                              {product.name}
                            </Text>
                          </View>

                          <View style={[styleHelper.containerText, {marginLeft: Device.getSize(2)}]}>
                            <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font13, {color: '#666'}]}>
                              {product.description_sale != null ? product.description_sale.substring(0,80) + '...' : null}
                            </Text>
                          </View>

                          <View style={[styleHelper.containerText, {marginLeft: Device.getSize(2)}]}>
                            {
                              product.list_price === 0 &&
                              <Text
                                style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font13]}>
                                  {
                                    product.product_variant_ids.length == 0 &&
                                    <Text style={[styleHelper.fontHelveticaNeueRegular]}>
                                      ${Math.round(product.list_price * 100) / 100}
                                    </Text>
                                  }
      
                                  {
                                    product.product_variant_ids.length > 1 &&
                                    <Text style={[styleHelper.fontHelveticaNeueRegular]}>
                                      Start From ${Math.round(product.product_variant_ids[0].lst_price * 100) / 100}
                                    </Text>
                                  }
                              </Text>
                            }
                            {
                              product.list_price > 0 &&
                              <Text
                                style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font13]}>
                                ${Math.round(product.list_price * 100) / 100}
                              </Text>
                            }
                          </View>
                        </View>
      
                        {/* <View style={[styleHelper.flex2]}> */}
                          <ImageProduct
                            imageID={product.id}
                            width={Device.getSize(110)}
                            height={Device.getSize(110)} />
                        {/* </View> */}
                      </View>

                    </TouchableOpacity>
                  </View>
                ))
              }
            </View>
        ))
      }
      </View>
    )
  }

  renderContent(sections){
      let categories = this.state.categories;
      let sortedProducts;
      // let images;
  
      return(
        <View style={[styles.contentBox, {minHeight: Device.height * 0.83}]}>
            <ScrollView ref={(ref)=>{
                this.myScroll = ref
              }}
              scrollEventThrottle={0}
              style={[{width: Device.width, height: Device.height * 0.83}]}
              onScroll={this.handleScroll}>
              {
                categories.map((category, i)=>{
                    return (
                      <View key={i}>
                        <View ref={ref => this.state.nodes.set(category.id, ref)} />
                        {
                          sections[category.id].subcategories.map((subcategory,j)=>{
                            if(subcategory.products) {
                              sortedProducts = this.sortProducts(subcategory.products);
                              // images = this.generateImageUris(subcategory.products);
  
                              if(sortedProducts.length > 1) {
                                return (
                                  <View key={j}>  
                                    <View style={[{marginBottom: Device.getSize(30)}]}>
                                      <View style={[{marginLeft: Device.getSize(20), marginBottom: Device.getSize(10)}]}>
                                        <Text style={[styleHelper.fontHelveticaNeueBold, styleHelper.font18, styleHelper.DarkRedFont]}
                                          ref={(ref) => {
                                            this.state.nodes.set(subcategory.id, ref);
                                          }}
                                          >
                                          {subcategory.name}
                                        </Text>
                                      </View>
                                      {this.renderProducts(sortedProducts)}
                                    </View>
                                  </View>
                                )
                              } else {
                                return (
                                  <View key={j}>
                                    <View ref={(ref) => {
                                      this.state.nodes.set(subcategory.id, ref);
                                    }} />
                                  </View>
                                )
                              }
                            }
                          })
                        }
                      </View>
                    )
                })
              }
            </ScrollView>
        </View>
      )
  }

  renderTopNav() {
    return(
      <View style={[styleHelper.flexRow, {backgroundColor: '#ca6d4f', height: Device.getSize(50)}]}>
          <View style={[styleHelper.flex1, {marginVertical: Device.getSize(15), marginLeft: Device.getSize(10)}]}>
              <TouchableOpacity onPress={()=>{ this.navigateWelcome(); }}>
                  <Text style={[styleHelper.fontWhite, styleHelper.font15, styleHelper.fontHelveticaNeueBold]}>
                      &lt;
                  </Text>
              </TouchableOpacity>
          </View>

          <View style={[styleHelper.flex1, styleHelper.flexCenter]}>
              <Text style={[styleHelper.fontWhite, styleHelper.font16, styleHelper.fontHelveticaNeueBold]}>
                  MENU
              </Text>
          </View>

          <View style={[styleHelper.flex1]} />
      </View>
    )
  }

  render() {
    return (
      <View>
        {this.renderProduct()}
        {this.renderSuggestion()}
        {this.renderPopupMsg()}
        {/* {this.renderPromo()}
        {this.renderVoucher()} */}

        <View>
          {this.renderTopNav()}
          {/* {this.renderCategorySlider()} */}
          {this.renderSubCategory(this.state.sections)}
        </View>

        <View style={[{minHeight: Device.height * 0.8}]}>
          {/* <ImageBackground style={[styleHelper.flexRow, styleHelper.flex1]}> */}
            {
              // !this.state.showPayment &&
              this.renderContent(this.state.sections)
            }
            {/* {
              this.state.showPayment &&
              this.renderPayment()
            } */}
            {/* {this.renderCart()} */}
            {
            <Cart />
            }
          {/* </ImageBackground> */}
        </View>
      </View>
    )
  }
}

// PRODUCTS
const ProductDescription = (props)=>{
  return(
    <Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.font12]}>
      {props.description || ''}
    </Text>
  )
}

// Modifiers
const IconPlus = (props)=>{
	if(props.disabled === true){
		return(
			<View style={{opacity: 0.5}}>
				<ImageIconPlus/>
			</View>
		)
	}

	return(
		<TouchableOpacity
			onPress={props.onPress}
			activeOpacity={Config.activeOpacity}>
			<ImageIconPlus/>
    </TouchableOpacity>
	)

}

const IconMinus = (props)=>{
	if(props.disabled === true){
		return(
			<View style={{opacity: 0.5}}>
				<ImageIconMinus/>
			</View>
		)
	}

	return(
		<TouchableOpacity
			onPress={props.onPress}
			activeOpacity={Config.activeOpacity}>
			<ImageIconMinus/>
    </TouchableOpacity>
	)
}

const BoxQuantity = (props)=>{
	return(
		<View style={styleHelper.flexRowSpaceBetween}>
			<IconMinus onPress={props.onPressMinus} />
			<View style={[styleHelper.inputBorderRadius, styleHelper.bgWhite, styleHelper.containerNumber ]}>
				<Text style={[styleHelper.fontHelveticaNeueRegular, styleHelper.fontBlack, styleHelper.font14, styleHelper.textCenter]}>
					{props.modifier.quantity}
				</Text>
			</View>
			<IconPlus onPress={props.onPressPlus} disabled={props.disabled}/>
		</View>
	)
}

const ImageIconPlus = ()=>{
	return(
		<Image 
      source={require('../img/ic_add_black.png')}
      style={styleHelper.bitsmallIconSize}
      resizeMode={'contain'}/>
	)
}

const ImageIconMinus = ()=>{
	return(
		<Image 
      source={require('../img/ic_minus_black.png')}
      style={styleHelper.bitsmallIconSize}
      resizeMode={'contain'}/>
	)
}

const TouchableRadio = (props)=>{
  let active = require('../img/radiobutton_active_dark.png');
  let inactive = require('../img/radiobutton_inactive_dark.png');

	let renderRadio = (selected)=> {
		if(selected === true) {
			return(
				<Image
					source={active}
					style={styleHelper.smallIconSize}
					resizeMode={'contain'}/>
			)
		}

		return(
			<Image
				source={inactive}
				style={styleHelper.smallIconSize}
				resizeMode={'contain'}/>
		)

	}

  if(props.disabled) {
    return (
      <View>
        {renderRadio(props.selected)}
      </View>
    )
  }

	return(
		<TouchableOpacity
			activeOpacity={1}
			onPress={()=>props.onPress(props.modifier, props.modifierGroup, props.index)}
			>

			{renderRadio(props.selected)}

		</TouchableOpacity>
	)
}

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.onCartChange = this.onCartChange.bind(this);
    this.state = {
      grandTotal: CartStore.getGrandTotal(),
    };
  }

  componentDidMount() {
    CartStore.addChangeProductCart(this.onCartChange);
  }

  componentWillUnmount() {
    CartStore.removeChangeProductCart(this.onCartChange);
  }

  onCartChange() {
    setTimeout(() => {
      this.setState({
        grandTotal: CartStore.getGrandTotal(),
      });
    }, 500);
  }
  
  navigateToCart() {
    if (CartStore.hasProducts() === true) {
      Navigation.navigate('Cart');
    } else {
      Services.showAlertError('There are currently no items in your cart.');
    }
  }

  render() {
    let itemCount = CartStore.getQuantity();
    if(this.state.grandTotal <= 0 && itemCount <= 0) {
      return <View />
    }

    return (
      <View style={[styles.cartSection]}>
        <TouchableOpacity
          onPress={() => this.navigateToCart()}
          activeOpacity={Config.activeOpacity}
          style={[styleHelper.flexRow, styleHelper.bgOrange, styles.cartButton]}>
            <View style={[styleHelper.flex1]}>
                <Text
                  style={[
                    styleHelper.fontBold,
                    styleHelper.fontWhite,
                    styleHelper.font16,
                    styles.cartQtyBox
                  ]}>
                  {itemCount}
                </Text>
            </View>

            <View style={[styleHelper.flexCenter, styleHelper.flex1]}>
              <Text
                style={[
                  styleHelper.fontBold,
                  styleHelper.fontWhite,
                  styleHelper.font16,
                ]}>
                View Basket
              </Text>
            </View>

            <View style={[styleHelper.flex1, {paddingTop: Device.getSize(5)}]}>
              <Text
                style={[
                  styleHelper.fontBold,
                  styleHelper.fontWhite,
                  styleHelper.font16,
                  {textAlign: 'right'}
                ]}>
                {Services.formatPrice(this.state.grandTotal)}
              </Text>
            </View>
        </TouchableOpacity>
      </View>
    )
  }
}
import AppDispatcher from '../Dispatcher/AppDispatcher';
import Constants from '../Constants/AppConstants';

const CartActions = {
	addProductToCart(product, qty) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.ADD_PRODUCT_TO_CART,
            product: product,
            qty: qty,
        })
    },
    removeProduct(cartID) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.REMOVE_PRODUCT_FROM_CART,
            cartID: cartID
        })
    },
    postOrder(store, errorCallback = true, isRedemption = false){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.AJAX_POST_ORDER,
            store: store,
            errorCallback: errorCallback,
            isRedemption: isRedemption
        })
    },
    // submitPromoCode(code, auto = false){
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.SUBMIT_PROMO_CODE,
    //         code: code,
    //         auto: auto,
    //     })
    // },
    // selectVoucher(voucherID) {
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.SELECT_VOUCHER,
    //         voucherID: voucherID
    //     })
    // },
    // selectTssVoucher(voucherID, voucherCode) {
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.SELECT_TSS_VOUCHER,
    //         voucherID: voucherID,
    //         voucherCode: voucherCode
    //     })
    // },
    // deletePromotion(promotionID, voucherID){
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.DELETE_PROMOTION,
    //         promotionID,
    //         voucherID,
    //     })
    // },
    updateCartAfterChangeStore(store, callback){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.UPDATE_CART_AFTER_CHANGE_STORE,
            store: store,
            callback: callback,
        })
    },
    setPaymentID(id){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_PAYMENT_ID,
            paymentID: id,
        })
    },
    reset(){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.RESET_CART,
        })
    },
    //Add user's souperholic points to cart
    // setRewardPoints(points){
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.SET_REWARD_POINTS_IN_CART,
    //         points: points,
    //     })
    // },
    // setGiftCardRewardPoints(points){
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.SET_GIFT_CARD_REWARD_POINTS_IN_CART,
    //         points: points,
    //     })
    // },
    // getAllPromotions(){
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.AJAX_GET_ALL_PROMOTIONS,
    //     })
    // },

    // setCartExpired(){
    //     AppDispatcher.dispatch({
    //         type: Constants.ActionTypes.SET_CART_EXPIRED,
    //     })
    // },
    setPaymentRemarks(paymentRemarks){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_PAYMENT_REMARKS,
            paymentRemarks: paymentRemarks
        })
    },
    setPaymentAmount(amount){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_PAYMENT_AMOUNT,
            amount: amount
        })
    },
    setPaymentTransId(transId){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_PAYMENT_TRANSID,
            transId: transId
        })
    },
    setCartID(id){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_CART_ID,
            cartID: id
        })
    },
    setCustomCartId(cartId) {
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_CUSTOM_CART_ID,
            cartId: cartId,
        });
    },
    setCartChanged(cartChanged){
        AppDispatcher.dispatch({
            type: Constants.ActionTypes.SET_CART_CHANGED,
            cartChanged: cartChanged
        })
    }
}

export default CartActions;
